import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { getordersdetails } from "../Actions/Action";
import ReviewContainer from "./ReviewContainer";
import TrackingOrder from "./TrackingOrder";
function Getorderdetails() {
  const [show, setShow] = useState(false);
  const { id } = useParams();
  const [getid, setGetid] = useState({
    orderId: id,
  });
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getordersdetails(getid));
  }, []);
  useEffect(() => {
    window.scrollTo(1000, 0);
  }, []);
  const getdetails = useSelector(
    ({ getordersdetails }) => getordersdetails.payload
  );
  return (
    <div>
      <div className="page-title-area">
        <div className="container">
          <div
            className="page-title-content"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <h2>Order Summary</h2>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/Profile">Orders</Link>
              </li>
              <li>Order Summary</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div>
          <TrackingOrder />
        </div>
      </div>
      <section className="cart-area ptb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 mt-5 mb-5">
              <form>
                <div className="cart-table table-responsive">
                  <table className="table table-bordered">
                    {Array.isArray(getdetails?.productInfo) ? (
                      <thead>
                        {/* <h3 className="mb-3"> Orders</h3> */}
                        <tr>
                          <th scope="col" style={{ textAlign: "center" }}>
                            S.No
                          </th>
                          <th scope="col" style={{ textAlign: "center" }}>
                            Name
                          </th>
                          <th scope="col" style={{ textAlign: "center" }}>
                            Product
                          </th>
                          <th scope="col" style={{ textAlign: "center" }}>
                            Unit Price
                          </th>
                          {/* <th scope="col" style={{ textAlign: "center" }}>
                            Variant Color
                          </th> */}
                          <th scope="col" style={{ textAlign: "center" }}>
                            Total
                          </th>
                          <th scope="col" style={{ textAlign: "center" }}>
                            Review
                          </th>
                        </tr>
                      </thead>
                    ) : (
                      false
                    )}
                    <tbody>
                      {Array.isArray(getdetails?.productInfo) &&
                      getdetails?.productInfo?.length !== 0 ? (
                        getdetails?.productInfo?.map((w, i) => (
                          <tr>
                            <td
                              style={{ textAlign: "center" }}
                              className="product-name"
                            >
                              <a style={{ color: "black" }}>{i + 1}</a>
                            </td>
                            <td
                              style={{ textAlign: "center" }}
                              className="product-name"
                            >
                              <a
                                style={{
                                  color: "black",
                                  textTransform: "capitalize",
                                }}
                              >
                                {w.productName}
                              </a>
                            </td>
                            <td
                              style={{ textAlign: "center" }}
                              className="product-thumbnail"
                            >
                              <a>
                                <img
                                  src={
                                    process.env.REACT_APP_BASE_URL +
                                    w.variantImage
                                  }
                                  alt="item"
                                />
                              </a>
                            </td>
                            <td
                              style={{ textAlign: "center" }}
                              className="product-price"
                            >
                              <span className="unit-amount">{w.units}</span>
                            </td>
                            {/* <td
                              style={{ textAlign: "center" }}
                              className="product-price"
                            >
                              <span className="unit-amount">
                                {w.variantColor}
                              </span>
                            </td> */}
                            <td
                              style={{ textAlign: "center" }}
                              className="product-price"
                            >
                              <span className="unit-amount">
                                ₹{w.totalPrice}
                              </span>
                            </td>
                            <td
                              style={{ textAlign: "center" }}
                              className="product-price"
                            >
                              <span className="unit-amount">
                                {w.isReviewed == 0 ? (
                                  <span
                                    className="btn btn-dark"
                                    onClick={() => {
                                      if (show === i) setShow("");
                                      else setShow(i);
                                    }}
                                  >
                                    {show === i
                                      ? "Close Review"
                                      : "Write Review"}
                                  </span>
                                ) : (
                                  <button
                                    className="btn btn-dark"
                                    disabled={true}
                                  >
                                    Reviewed
                                  </button>
                                )}
                              </span>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <h3>"No order Found!"</h3>
                      )}
                    </tbody>
                  </table>
                </div>
              </form>
            </div>
          </div>
        </div>
        {show || show === 0 ? (
          <ReviewContainer order={getdetails?.productInfo?.[show]} />
        ) : (
          false
        )}
      </section>
    </div>
  );
}

export default Getorderdetails;
