import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getcategoryproducts, addwishlists } from "../Actions/Action";
import { FaHeart, FaRegHeart } from "react-icons/fa";
import products from "../noproductsfound.svg";

function CategoryShop() {
  const [load, setLoad] = useState(false);

  const { id } = useParams();

  const dispatch = useDispatch();

  const categoryList = useSelector(
    ({ getcategories }) => getcategories.payload
  );

  const catproduct = useSelector(
    ({ getcategoryproducts }) => getcategoryproducts.payload
  );

  const currentCategoryName =
    categoryList?.find((item) => item?.id == id)?.categoryName || "";

  useEffect(() => {
    dispatch(
      getcategoryproducts({
        categoryId: id,
      })
    );
  }, [dispatch, id]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const renderList =
    (Array.isArray(catproduct) &&
      catproduct.filter((F) => F.discountPrice > 1)) ||
    [];

  return (
    <div>
      {/* <div class="page-title-area">
        <div class="container">
          <div
            class="page-title-content"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <h2>Shop </h2>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>Shop</li>
            </ul>
          </div>
        </div>
      </div> */}

      <section class="shop-area pt-100 pb-100">
        <div class="container">
          <div class="woocommerce-topbar">
            <div class="row align-items-center">
              <div class="col-lg-8 col-md-7 col-sm-6">
                <div class="woocommerce-result-count">
                  <p>
                    Results for
                    <span
                      className="text-capitalize"
                      style={{ fontWeight: "500" }}
                    >
                      {"  " + currentCategoryName}'s
                    </span>
                  </p>
                </div>
              </div>
              {/* <div class="col-lg-4 col-md-5 col-sm-6">
                <div class="woocommerce-topbar-ordering"></div>
              </div> */}
            </div>
          </div>
          <div class="row">
            {renderList.length !== 0 ? (
              renderList.map((pro) => (
                <div class="col-xl-3 col-lg-4 col-6">
                  <div class="products-item">
                    <div class="image">
                      <Link to={"/ProductDetails/" + pro.id}>
                        <img
                          src={
                            process.env.REACT_APP_BASE_URL + pro.productImage
                          }
                          alt={pro.productName}
                          style={{
                            width: "100%",
                            aspectRatio: "1 / 1",
                          }}
                        />
                      </Link>
                      <ul class="social" style={{ marginTop: "-60px" }}>
                        <li>
                          <a
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              fontSize: "20px",
                            }}
                            onClick={() => {
                              setLoad(true);
                              dispatch(
                                addwishlists({ productId: pro.id })
                              ).then((res) => {
                                setLoad(false);
                              });
                            }}
                          >
                            {load ? (
                              <></>
                            ) : pro.favourites === 0 ? (
                              <span>
                                <FaRegHeart style={{ color: "black" }} />
                              </span>
                            ) : (
                              <span>
                                <FaHeart style={{ color: "black" }} />
                              </span>
                            )}
                          </a>
                        </li>
                      </ul>
                      <div class="new">{/* <span>New</span> */}</div>
                      <div class="shop-btn">
                        <Link
                          to={"/ProductDetails/" + pro.id}
                          class="default-btn"
                        >
                          View Product
                        </Link>
                      </div>
                    </div>
                    <div class="content">
                      <h3>
                        <Link to={"/ProductDetails/" + pro.id}>
                          {pro.productName}
                        </Link>
                      </h3>
                      <span>₹{pro.discountPrice}</span>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <center className="pb-5 mb-5">
                <img
                  src={products}
                  className="mt-5 mb-5"
                  style={{ width: "25%" }}
                  alt=""
                />
                <h3 className="container">No product found is this category</h3>
              </center>
            )}
          </div>
        </div>
      </section>
    </div>
  );
}

export default CategoryShop;
