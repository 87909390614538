import React, { useEffect, useState } from "react";
import { getAddress, DeleteAddress } from "../Actions/Action";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
function GetAddress(props) {
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAddress());
  }, []);

  const getaddr = useSelector(({ getAddress }) => getAddress.payload);
  const font = {
    fontWeight: "bold",
    textTransform: "Capitalize",
    textAlign: "center",
  };
  const weight = {
    textTransform: "Capitalize",
    color: "black",
    textAlign: "center",
  };
  const updat = {
    cursor: "pointer",
    textAlign: "center",
  };
  useEffect(() => {
    window.scrollTo(1000, 0);
  }, []);

  return (
    <div className="container mb-5 mt-5 pb-5 pt-5">
      <div className="cart-table table-responsive">
        {Array.isArray(getaddr) && getaddr.length !== 0 ? (
          <h2 className="d-flex justify-content-center">Address List</h2>
        ) : (
          false
        )}
        <table class="table table-bordered">
          {Array.isArray(getaddr) ? (
            <thead>
              <tr>
                <th scope="col" style={font}>
                  S.No
                </th>
                <th scope="col" style={font}>
                  Address Type
                </th>
                <th scope="col" style={font}>
                  Address
                </th>
                {/* <th scope="col" style={font}>
                  City
                </th>
                <th scope="col" style={font}>
                  District
                </th>
                <th scope="col" style={font}>
                  Landmark
                </th> */}
                <th scope="col" style={font}>
                  State
                </th>
                {/* <th scope="col" style={font}>
                  Street
                </th> */}
                <th scope="col" style={font}>
                  Pincode
                </th>
                <th scope="col" style={font}>
                  Primary
                </th>
                <th scope="col" style={font}>
                  Action
                </th>
                {/* <th scope="col" style={font}>
                  Action
                </th> */}
              </tr>
            </thead>
          ) : (
            false
          )}
          <tbody>
            {Array.isArray(getaddr) && getaddr.length !== 0
              ? getaddr?.map((G, i) => (
                  <tr>
                    <td style={weight}>{i + 1}</td>
                    <td style={weight}>{G.addressType}</td>

                    <td
                      style={weight}
                    >{`${G.city}, ${G.district} ${G.landmark}, ${G.street}`}</td>

                    {/* <td style={weight}>{G.city}</td>
                    <td style={weight}>{G.district}</td> */}
                    {/* <td style={weight}>{G.landmark}</td> */}
                    <td style={weight}>{G.state}</td>
                    {/* <td style={weight}>{G.street}</td> */}
                    <td style={weight}>{G.zipcode}</td>
                    <td style={weight}>{G.primary}</td>
                    <td style={updat}>
                      <span className="badge bg-success">
                        <Link
                          style={{ color: "white" }}
                          to={"/UpdateAddress/" + G.id}
                        >
                          Edit{" "}
                        </Link>
                      </span>
                    </td>
                    <td style={updat}>
                      <span
                        className="badge bg-danger"
                        style={{ color: "white" }}
                        onClick={() => {
                          dispatch(
                            DeleteAddress({
                              addressId: G.id,
                              action: "delete",
                            })
                          )
                            .then((res) => {
                              Swal.fire(res?.data?.data);
                              dispatch(getAddress());
                            })
                            .catch((err) => {
                              setError(err.response.data.error.message);
                            });
                        }}
                      >
                        Delete
                      </span>
                    </td>
                  </tr>
                ))
              : false}
          </tbody>
        </table>
      </div>
      {error ? (
        <div className="conatiner pt-5">
          <div class="alert alert-danger" role="alert">
            <center> {error} </center>
          </div>
        </div>
      ) : (
        false
      )}
    </div>
  );
}

export default GetAddress;
