import React, { useEffect, useState } from "react";
import { BsCart4 } from "react-icons/bs";
import { CgClose } from "react-icons/cg";
import { FiHeart } from "react-icons/fi";

import { RxHamburgerMenu } from "react-icons/rx";
import { PiUserCircleThin } from "react-icons/pi";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";

import styled from "styled-components";
import { getcartcounts, logouts, getcategories } from "../Actions/Action";
import "./Header.css";
import { Logo } from "./components/Logo.jsx";

const CategoryList = styled.ul`
  list-style: none;
  padding: 15px 20px !important;
  background-color: #fff;
  box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  width: 300px;

  h2 {
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 15px;
  }
`;

const ListItem = styled.li`
  margin-bottom: 5px;
`;

const ListItemLink = styled(NavLink)`
  text-transform: capitalize;
  cursor: pointer;
  color: #808080 !important;
  margin: 0 !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  padding: 0 !important;

  &:hover,
  &.active {
    text-decoration: underline;
    color: #000000 !important;
  }
`;

function Header() {
  const [menuListShow, setMenuListShow] = useState(false);
  const menuListToggle = () => {
    setMenuListShow(!menuListShow);
  };

  const dispatch = useDispatch();

  const Count = useSelector(({ getcartcounts }) => getcartcounts.payload);
  const categoryList = useSelector(
    ({ getcategories }) => getcategories.payload
  );

  useEffect(() => {
    dispatch(getcategories());

    dispatch(getcartcounts())
      .then((res) => {})
      .catch((err) => {
        if (err.response.data.error.message == "UnAuthorized") {
          dispatch(logouts());
          localStorage.removeItem("AuthTok");
          window.location.href = "/";
        }
      });
  }, []);

  const Token = localStorage.getItem("AuthTok");

  const styles = {
    textAlign: "center",
    fontWeight: "bolder",
    marginTop: "10px",
    fontSize: "22px",
    cursor: "pointer",
    "styles:hover": { color: "blue" },
  };

  const [categoryListShow, setCategoryListShow] = useState(false);
  const showCategoryList = () => {
    setCategoryListShow(true);
  };
  const hideCategoryList = () => {
    setCategoryListShow(false);
  };

  return (
    <>
      {/* Header one */}
      {/* <div class="top-header">
        <div class="container">
          <div class="row align-items-center" style={{ marginLeft: "10px" }}>
            <div class="col-lg-6">
              <ul class="header-list">
                <li>
                  <i class="flaticon-bag"></i>
                  Welcome to Heboo
                </li>
                <li>
                  <i class="flaticon-delivery-truck"></i>
                  Shipping Worldwide
                </li>
              </ul>
            </div>
            <div class="col-lg-6">
              <ul class="header-social">
                <li>
                  <a target="_blank">
                    <i class="fas fa-envelope"></i>
                  </a>
                </li>
                <li>
                  <a target="_blank">
                    <i class="flaticon-facebook"></i>
                  </a>
                </li>
                <li>
                  <a target="_blank">
                    <i class="flaticon-twitter"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div> */}

      {/* Header Two */}

      <div class="navbar-area ">
        {/* small screen view */}
        <div class="main-responsive-nav mb-2">
          <div className="container">
            <div
              className="d-flex justify-content-between align-items-center "
              style={{ paddingLeft: "9px", paddingRight: "9px" }}
            >
              <Link to="/">
                <Logo
                  src="/assets/img/heboo-logo-dark.svg"
                  alt="heboo-logo-dark"
                />
              </Link>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "20px",
                  lineHeight: "1",
                }}
              >
                {Token ? (
                  <>
                    <Link to="/cart">
                      <BsCart4
                        size={30}
                        style={{
                          cursor: "pointer",
                        }}
                      />
                    </Link>

                    <Link to="/Wishlist">
                      <FiHeart
                        size={28}
                        style={{
                          cursor: "pointer",
                        }}
                      />
                    </Link>
                  </>
                ) : null}

                {!menuListShow ? (
                  <RxHamburgerMenu
                    size={30}
                    onClick={menuListToggle}
                    style={{ cursor: "pointer" }}
                  />
                ) : (
                  <CgClose
                    size={30}
                    onClick={menuListToggle}
                    style={{ cursor: "pointer" }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>

        {/* menu list , small screen view*/}
        {menuListShow ? (
          <div className="responsive">
            <ul className="p-0 mt-2 mb-3" style={{ listStyleType: "none" }}>
              <li className="hover" onClick={menuListToggle} style={styles}>
                <NavLink to="/"> Home </NavLink>
              </li>
              <li className="hover" onClick={menuListToggle} style={styles}>
                <NavLink to="/Shop"> Shop </NavLink>
              </li>
              <li className="hover" style={styles} onClick={menuListToggle}>
                <NavLink to="/Categories"> Categories</NavLink>{" "}
              </li>
              {/* <li className="hover" onClick={menuListToggle} style={styles}>
                <NavLink to="/About"> About </NavLink>
              </li> */}
              {Token ? (
                <li className="hover" onClick={menuListToggle} style={styles}>
                  <NavLink to="/Profile"> Profile</NavLink>{" "}
                </li>
              ) : (
                <li className="hover" onClick={menuListToggle} style={styles}>
                  <NavLink to="/login"> Login</NavLink>{" "}
                </li>
              )}
              <li className="hover" onClick={menuListToggle} style={styles}>
                <NavLink to="/Contact"> Contact</NavLink>{" "}
              </li>
            </ul>
          </div>
        ) : null}

        {/* large screen view */}
        <div class="main-navbar">
          <div class="container">
            <nav class="navbar navbar-expand-md  navbar-light d-flex justify-content-between align-items-center">
              <Link to="/">
                <Logo
                  src="/assets/img/heboo-logo-dark.svg"
                  alt="heboo-logo-dark"
                />
              </Link>

              {/*center menu items */}
              <ul
                class="navbar-nav m-0"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  maxWidth: "fit-content",
                  gap: "20px",
                }}
              >
                <li class="nav-item ">
                  <NavLink
                    to="/"
                    class="nav-link "
                    style={{ padding: "0", margin: "0" }}
                  >
                    Home
                  </NavLink>
                </li>
                <li class="nav-item ">
                  <NavLink to="/Shop" class="nav-NavLink">
                    Shop
                  </NavLink>
                </li>
                <li
                  class="nav-item"
                  style={{ position: "relative" }}
                  onMouseOver={showCategoryList}
                  onMouseLeave={hideCategoryList}
                >
                  <NavLink
                    to="/Categories"
                    class="nav-link"
                    style={{
                      margin: "0",
                      padding: "0",
                      lineHeight: "1",
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <span>Categories</span>
                    <i class="bx bx-chevron-down"></i>
                  </NavLink>

                  {categoryListShow && (
                    <div
                      style={{
                        position: "absolute",
                        zIndex: "100",
                        left: "0px",
                        top: "100%",
                      }}
                    >
                      <div
                        style={{
                          height: "20px",
                        }}
                      ></div>
                      <CategoryList>
                        <h2>Categories</h2>
                        {Array.isArray(categoryList) &&
                        categoryList.length !== 0
                          ? categoryList.map((category, index) => {
                              return (
                                <ListItem
                                  key={category?.categoryName + index}
                                  onClick={hideCategoryList}
                                >
                                  <ListItemLink
                                    to={`/CategoryShop/${category?.id}`}
                                  >
                                    {category?.categoryName}
                                  </ListItemLink>
                                </ListItem>
                              );
                            })
                          : null}
                      </CategoryList>
                    </div>
                  )}
                </li>
                {/* <li class="nav-item">
                  <NavLink to="/About" class="nav-link">
                    About
                  </NavLink>
                </li> */}

                <li class="nav-item">
                  <NavLink to="/Contact" class="nav-link">
                    Contact
                  </NavLink>
                </li>
              </ul>

              {/* right most group of items */}
              <div class="others-option d-flex align-items-center">
                {/* <div class="option-item">
                  <div class="info">
                    <div
                      class="icon"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <i class="flaticon-email"></i>
                    </div>
                    <span>Email </span>
                    <p>
                      <a
                        href="mailto:heboo.in@gmail.com"
                        style={{
                          fontWeight: "bolder",
                          color: "black",
                          cursor: "pointer",
                        }}
                      >
                        heboo.in@gmail.com
                      </a>
                    </p>
                  </div>
                </div> */}

                <div class="option-item">
                  <div class="info">
                    <div class="icon">
                      <i class="flaticon-call"></i>
                    </div>
                    {/* <span>Call </span> */}
                    <p>
                      <a
                        href="tel:+919965488888"
                        style={{ fontWeight: "bolder", color: "black" }}
                      >
                        +91 99654 88888
                      </a>
                    </p>
                  </div>
                </div>

                {/* icons, login, logout related */}
                {Token ? (
                  <div class="option-item">
                    <div class="cart-btn">
                      <Link to="/cart">
                        <BsCart4 size={30} />
                        {Count?.count > 0 ? <span>{Count.count}</span> : false}
                      </Link>
                    </div>
                  </div>
                ) : (
                  false
                )}

                {Token ? (
                  <div class="option-item">
                    <div class="user">
                      <Link to="/profile">
                        <PiUserCircleThin size={40} />
                      </Link>
                    </div>
                  </div>
                ) : (
                  <div class="option-item">
                    <div class="user">
                      <Link to="/login">
                        <span style={{ paddingRight: "5px" }}>
                          <PiUserCircleThin size={42} />
                        </span>
                        {/* <i id="unique" class="flaticon-user"></i> */}
                        <span
                          style={{
                            fontWeight: "500",
                            lineHeight: "1",
                            verticalAlign: "text-bottom",
                          }}
                        >
                          Sign in
                        </span>
                      </Link>
                    </div>
                  </div>
                )}

                {Token ? (
                  <div class="option-item">
                    <div class="heart">
                      <Link to="/Wishlist">
                        <FiHeart size={30} />
                      </Link>
                    </div>
                  </div>
                ) : (
                  false
                )}
                {/*  */}
              </div>
            </nav>
          </div>
        </div>

        {/* {hov  ? <span className="container d-flex justify-content-end"> <button className="default-btn" style={{marginTop:"-20px"}}>Logout</button></span> : false} */}
        <div class="others-option-for-responsive">
          <div class="container">
            <div class="dot-menu">
              <div class="circle circle-one"></div>
              <div class="circle circle-two"></div>
              <div class="circle circle-three"></div>
            </div>
            <div className="container">
              <div className="main-responsive-menu mean-container">
                <div className="mean-bar">
                  <a className="meanmenu-reveal">
                    <i class="fas fa-bars"></i>
                  </a>
                </div>
              </div>
            </div>
            <div class="container">
              <div class="option-inner">
                <div class="others-option justify-content-center d-flex align-items-center">
                  <div class="option-item">
                    <div class="info">
                      <div class="icon">
                        <i class="flaticon-email"></i>
                      </div>
                      {/* <span>Email </span> */}
                      <p>
                        <a
                          href="mailto:heboo.in@gmail.com"
                          style={{ cursor: "pointer" }}
                        >
                          <span class="__cf_email__" data-cfemail="">
                            heboo.in@gmail.com
                          </span>
                        </a>
                      </p>
                    </div>
                  </div>
                  <div class="option-item">
                    <div class="info">
                      <div class="icon">
                        <i class="flaticon-call"></i>
                      </div>
                      {/* <span>Call </span> */}
                      <p>
                        <a href="tel:+91 99654 88888">+91 99654 88888</a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;
