import React, { useEffect, useState } from "react";
import { Link, useSearchParams, Navigate } from "react-router-dom";

import SuccessImage from ".././views/assests/SuccessImage.png";
import FailedImage from ".././views/assests/FailedImage.png";
import { axiosInstance } from "../Services";

function Paymentverified() {
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [load, setLoad] = useState(true);

  const [URLSearchParams] = useSearchParams();
  const paymentIntent = URLSearchParams.get("payment_intent");

  const confirmPlaceOrder = async () => {
    try {
      const {
        data: { data },
      } = await axiosInstance(
        "post",
        process.env.REACT_APP_BASE_URL + "/user/orders/payment",
        {
          paymentIntent,
        }
      );
      setPaymentStatus(data);
    } catch (error) {
      return null;
    } finally {
      setLoad(false);
    }
  };

  useEffect(() => {
    confirmPlaceOrder();
    return () => {};
  }, []);

  if (!paymentIntent) return <Navigate to={"/"} />;

  const isError = paymentStatus !== "Order Placed Successfully";

  if (load)
    return (
      <div
        style={{
          height: "700px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    );

  return (
    <div
      className="mt-5 pt-5 pb-5 mb-5"
      style={{ dispay: "flex", justifyContent: "center" }}
    >
      {!isError ? (
        <center>
          {" "}
          <img
            src={SuccessImage}
            style={{ width: "400px" }}
            alt="SuccessImage"
          />
          <h1> {paymentStatus && paymentStatus}</h1>
          <Link to="/Profile?tabId=2" class="default-btn mr-3">
            Track Order
          </Link>{" "}
          &nbsp; &nbsp;
          <Link to="/shop" class="default-btn mt-5 mb-5 mr-3">
            {" "}
            Keep Shopping
          </Link>
        </center>
      ) : (
        <center>
          {" "}
          <img
            src={FailedImage}
            style={{ width: "400px" }}
            alt="FailedImage"
          />{" "}
          <h1> {paymentStatus && paymentStatus}</h1>
          &nbsp; &nbsp;
          <Link to="/cart" class="default-btn mt-5 mb-5 mr-3">
            {" "}
            Go to Cart
          </Link>
        </center>
      )}
    </div>
  );
}

export default Paymentverified;
