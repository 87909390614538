import React,{useState,useEffect} from 'react'
import {Link, useNavigate } from 'react-router-dom'
import {useDispatch } from 'react-redux'
import {resetpasswords} from '../Actions/Action'
function ForgotPassword() {
  const [email,setEmail]=useState("")
  const [error,setError]=useState("")
const dispatch = useDispatch()
const navigate=useNavigate()
    useEffect(()=>{
        window.scrollTo( 1000,0);
       setError(localStorage.getItem("Error"))
      },[])

      const handleSubmit = (e) => {
        e.preventDefault()
        e.currentTarget.reset()
        dispatch(resetpasswords({email:email})).then(()=> { 
          navigate('/VerifyPassword/' + email )
        }).catch(err=>setError(err.response.data.error.message))
      }

    return (
  <div>            
<div class="page-title-area">
<div class="container">
<div class="page-title-content"  style={{display:"flex",justifyContent:"space-between"}}>
<h2>Forgot Password</h2>
<ul>
<li><Link to="/">Home</Link></li>
<li>Forgot Password</li>
</ul>
</div>
</div>
</div>
<section class="forgot-password-area ptb-100">
<div class="container">
<div class="forgot-password-form">
<div class="forgot-password-in-title">
<h3>Forgot Your Password</h3> 
</div>
<form onSubmit={handleSubmit}>
<div class="row">
<div class="col-lg-12">
<div class="form-group">
<input type="email" onChange={e=>setEmail(e.target.value)} class="form-control" placeholder="Enter Your Email" required/>
</div>
</div>
   <span className='mb-1 pb-3' style={{color:"red"}}> {error}</span> 
<div class="col-lg-12">
<div class="send-btn">
<button type="submit" class="default-btn">
Send Email  
<span></span>
</button>
</div>
</div>
</div>
</form>
</div>
</div>
</section>


        </div>
    )
}

export default ForgotPassword
