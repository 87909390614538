import React, { useEffect, useState } from "react";
import "./TrackingOrder.css";
import { useParams } from "react-router-dom";
import { getordersdetails } from "../Actions/Action";
import { useSelector, useDispatch } from "react-redux";
function TrackingOrder() {
  const { id } = useParams();
  const [getid, setGetid] = useState({
    orderId: id,
  });
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getordersdetails(getid));
  }, []);
  const getdetails = useSelector(
    ({ getordersdetails }) => getordersdetails.payload
  );
  return (
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 col-md-10 pt45 pb20 mt-5">
          <div>
            <div
              class="row display-flex justify-content-center ml-5 mt-5 mb-5"
              style={{ marginLeft: "200px" }}
            >
              <div
                class={
                 getdetails?.orderInfo?.orderStatus === "accepted"  || getdetails?.orderInfo?.orderStatus ==="packaging" || getdetails?.orderInfo?.orderStatus ==="dispatched" || getdetails?.orderInfo?.orderStatus ==="delivered"? "order-tracking completed": "order-tracking"
                }
              >
                <span class="is-complete"></span>
                <p>
                  Accepted
                  <br />
                  <span>Mon, June 24</span>
                </p>
              </div>
              <div
                class={
                 getdetails?.orderInfo?.orderStatus === "packaging" || getdetails?.orderInfo?.orderStatus ===  "dispatched" ||getdetails?.orderInfo?.orderStatus ==="delivered" ?"order-tracking completed": "order-tracking"
                }
              >
                <span class="is-complete"></span>
                <p>
                  Packaging
                  <br />
                  <span>Fri, June 28</span>
                </p>
              </div>
              <div
                class={
                 getdetails?.orderInfo?.orderStatus === "dispatched" ||getdetails?.orderInfo?.orderStatus === "delivered" ? "order-tracking completed": "order-tracking"
                }
              >
                <span class="is-complete"></span>
                <p>
                  Dispatched
                  <br />
                  <span>Fri, June 28</span>
                </p>
              </div>
              <div
                class={
                 getdetails?.orderInfo?.orderStatus === "delivered" ? "order-tracking completed": "order-tracking"
                }
              >
                <span class="is-complete"></span>
                <p>
                  Delivered
                  <br />
                  <span>Fri, June 28</span>
                </p>
              </div>
            
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TrackingOrder;
