import React, { useState, useEffect } from "react";
import { getAddress, DeleteAddress } from "../Actions/Action";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";

function UpdateAddress() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const Cid = id;
  useEffect(() => {
    dispatch(getAddress()).then((res) => {
      res.data.data
        .filter((F) => F.id == Cid)
        .map((A) => {
          setAddress({
            state: A.state,
            city: A.city,
            street: A.street,
            landmark: A.landmark,
            zipcode: A.zipcode,
            primary: A.primary,
            district: A.district,
            addressType: A.addressType,
          });
        });
    });
  }, []);
  const getAddres = useSelector(({ getAddress }) => getAddress.payload);
  const [address, setAddress] = useState({
    state: "",
    city: "",
    street: "",
    landmark: "",
    zipcode: "",
    primary: "",
    district: "",
    addressType: "",
  });
  const handleChange = (e) => {
    setAddress({ ...address, [e.target.name]: e.target.value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(DeleteAddress({ ...address, action: "edit", addressId: Cid }))
      .then((res) => {
        Swal.fire(res?.data?.data);
        navigate("/profile");
      })
      .cacth();
  };
  return (
    <div>
      <div class="page-title-area">
        <div class="container">
          <div
            class="page-title-content"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <h2>Update Address</h2>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>

              <li>Update Address</li>
            </ul>
          </div>
        </div>
      </div>
      <section class="register-area ptb-100 mt-5 mb-5">
        <div class="container col-lg-4 d-flex justify-content-start">
          <div class="register-form">
            <div class="register-in-title">
              <h3 style={{ textAlign: "center" }}>Update Address</h3>
            </div>
            <form onSubmit={handleSubmit}>
              <div class="row">
                <div class="col-lg-6">
                  <div class="form-group">
                    <input
                      type="text"
                      name="state"
                      value={address.state}
                      onChange={handleChange}
                      class="form-control"
                      placeholder="State"
                      required
                    />
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group">
                    <input
                      type="text"
                      name="city"
                      value={address.city}
                      onChange={handleChange}
                      class="form-control"
                      placeholder="City"
                      required
                    />
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group">
                    <input
                      type="text"
                      name="street"
                      value={address.street}
                      onChange={handleChange}
                      class="form-control"
                      placeholder="Street"
                      required
                    />
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group">
                    <input
                      type="text"
                      name="landmark"
                      value={address.landmark}
                      onChange={handleChange}
                      class="form-control"
                      placeholder="Landmark"
                      required
                    />
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group">
                    <input
                      type="number"
                      name="zipcode"
                      value={address.zipcode}
                      onChange={handleChange}
                      class="form-control"
                      placeholder="Pincode"
                      required
                    />
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group">
                    <input
                      type="text"
                      name="primary"
                      value={address.primary}
                      onChange={handleChange}
                      class="form-control"
                      placeholder="Primary"
                      required
                    />
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group">
                    <input
                      type="text"
                      name="district"
                      value={address.district}
                      onChange={handleChange}
                      class="form-control"
                      placeholder="District"
                      required
                    />
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group">
                    <input
                      type="text"
                      name="addressType"
                      value={address.addressType}
                      onChange={handleChange}
                      class="form-control"
                      placeholder="AddressType"
                      required
                    />
                  </div>
                </div>
                <div class="col-lg-12">
                  <div
                    class="send-btn"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <button type="submit" class="default-btn">
                      Update Address
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </div>
  );
}

export default UpdateAddress;
