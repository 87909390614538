import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { getAddress, getcarts } from "../Actions/Action";

// styles
const font = {
  fontWeight: "bold",
  textTransform: "Capitalize",
  textAlign: "center",
  fontSize: "15px",
  padding: "0px",
  color: "gray",
};
const weight = {
  textTransform: "Capitalize",
  color: "black",
  textAlign: "center",
  fontSize: "15px",
  padding: "0px",
};
const updat = {
  cursor: "pointer",
  textAlign: "center",
};

function Checkout() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const getaddr = useSelector(({ getAddress }) => getAddress.payload);
  const getCart = useSelector(({ getcarts }) => getcarts.payload);

  const [selectedValue, setSelectedValue] = React.useState(null);
  const [load, setLoad] = useState(true);
  const [error, setError] = useState(false);
  const [render, setRender] = useState(false);

  useEffect(() => {
    dispatch(getAddress());
  }, []);

  useEffect(() => {
    window.scrollTo(1000, 0);
  }, []);

  useEffect(() => {
    dispatch(getcarts())
      .then((res) => setLoad(false))
      .catch((res) => setLoad(false));
  }, [render]);

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  function handlePlaceOrder() {
    navigate("/Payment", { state: { addressId: selectedValue } });
  }

  return (
    <div>
      <div className="page-title-area">
        <div className="container">
          <div
            className="page-title-content"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <h2>Checkout</h2>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>Checkout</li>
            </ul>
          </div>
        </div>
      </div>
      <section className="checkout-area ptb-100">
        <div className="container">
          <form>
            <div className="row">
              {/* Billing Details */}
              <div className="col-lg-7 col-md-12">
                <div className="billing-details">
                  <h3 className="title">Billing Details</h3>

                  <div className="container " style={{ padding: "0px" }}>
                    <div className="cart-table table-responsive">
                      {Array.isArray(getaddr) && getaddr.length !== 0 ? (
                        <table className="table table-bordered my-4">
                          <thead>
                            <tr>
                              <th scope="col" style={font}>
                                S.No
                              </th>
                              <th scope="col" style={font}>
                                Address Type
                              </th>
                              <th scope="col" style={font}>
                                Address
                              </th>
                              {/* <th scope="col" style={font}>
                  City
                </th>
                <th scope="col" style={font}>
                  District
                </th>
                <th scope="col" style={font}>
                  Landmark
                </th> */}
                              <th scope="col" style={font}>
                                State
                              </th>
                              {/* <th scope="col" style={font}>
                  Street
                </th> */}
                              <th scope="col" style={font}>
                                Pincode
                              </th>
                              <th scope="col" style={font}>
                                Primary
                              </th>
                              <th scope="col" style={font}>
                                Action
                              </th>
                            </tr>
                          </thead>
                          <tbody style={{ marginTop: "20px" }}>
                            {getaddr?.map((G, i) => (
                              <tr key={i}>
                                <td style={weight}>{i + 1}</td>
                                <td style={weight}>{G.addressType}</td>
                                <td
                                  style={weight}
                                >{`${G.city}, ${G.district} ${G.landmark}, ${G.street}`}</td>

                                {/* <td style={weight}>{G.city}</td> */}
                                {/* <td style={weight}>{G.district}</td> */}
                                {/* <td style={weight}>{G.landmark}</td> */}
                                <td style={weight}>{G.state}</td>
                                {/* <td style={weight}>{G.street}</td> */}
                                <td style={weight}>{G.zipcode}</td>
                                <td style={weight}>{G.primary}</td>
                                <td style={updat}>
                                  <span>
                                    <input
                                      type="radio"
                                      name="radio-buttons"
                                      value={G.id}
                                      onChange={handleChange}
                                      style={{
                                        background: "none",
                                        border: "none",
                                        boxShadow: "none",
                                        color: "black",
                                      }}
                                    />
                                  </span>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      ) : (
                        <h2
                          className="text-dark my-4 text-uppercase"
                          style={{ fontSize: "20px" }}
                        >
                          {getaddr}
                        </h2>
                      )}

                      <Link
                        to="/Profile?tabId=1&isFromCheckout=true"
                        className="default-btn order-btn"
                      >
                        Add Address
                      </Link>
                    </div>
                    {/* error msg select at leat an address */}
                    {error ? (
                      <div
                        className="alert alert-danger mt-4 text-center"
                        role="alert"
                      >
                        Please select an address to place order
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>

              {/* Your Order */}
              <div className="col-lg-5 col-md-12">
                <div className="order-details">
                  <h3 className="title">Your Order</h3>
                  <div className="order-table table-responsive">
                    {Array.isArray(getCart?.cart) &&
                    getCart?.cart?.length != 0 ? (
                      <div
                        className="payment-box"
                        style={{ boxShadow: "none", marginTop: "0px" }}
                      >
                        <div
                          className="payment-box"
                          style={{ marginTop: "0px" }}
                        >
                          <div
                            className=" col-6"
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            {/* labels */}
                            <div style={{ whiteSpace: "nowrap" }}>
                              <div
                                style={{ fontSize: "18px", marginTop: "20px" }}
                              >
                                Actual Price
                              </div>

                              <div
                                style={{
                                  fontSize: "18px",
                                  marginTop: "20px",
                                  marginRight: "5px",
                                }}
                              >
                                Discount {"(-)"}
                              </div>

                              <div
                                style={{ fontSize: "18px", marginTop: "20px" }}
                              >
                                {" "}
                                Total Price
                              </div>

                              {/* <div
                                style={{
                                  fontSize: "18px",
                                  marginTop: "20px",
                                  color: "grey",
                                }}
                              >
                                Sub Total
                              </div>

                              <div
                                style={{
                                  fontSize: "18px",
                                  marginTop: "20px",
                                  color: "grey",
                                }}
                              >
                                {" "}
                                GST
                              </div> */}
                            </div>
                            {/* values */}
                            <div style={{ whiteSpace: "nowrap" }}>
                              <div
                                style={{ fontSize: "18px", marginTop: "20px" }}
                              >
                                ₹ {getCart?.price}
                              </div>

                              <div
                                style={{ fontSize: "18px", marginTop: "20px" }}
                              >
                                ₹ {getCart?.discount}
                              </div>

                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "1px",
                                  fontSize: "18px",
                                  marginTop: "20px",
                                  color: "grey",
                                }}
                              >
                                <span>₹ {getCart?.totalPrice}</span>

                                <span style={{ fontSize: "10px" }}>
                                  Inclusive of all Taxes
                                </span>
                              </div>

                              {/* <div
                                style={{ fontSize: "18px", marginTop: "20px" }}
                              >
                                ₹ {getCart?.subtotal}
                              </div>

                              <div
                                style={{
                                  fontSize: "18px",
                                  marginTop: "20px",
                                  color: "grey",
                                }}
                              >
                                ₹ {getCart?.gst}
                              </div> */}
                            </div>{" "}
                          </div>
                          <span
                            // button : PlaceOrder
                            style={{ cursor: "pointer" }}
                            className="default-btn order-btn"
                            onClick={() => {
                              if (selectedValue) {
                                setError(false);
                                handlePlaceOrder();
                              } else {
                                setError(true);
                              }
                            }}
                          >
                            Place Order
                          </span>
                        </div>
                      </div>
                    ) : (
                      false
                    )}
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </section>
    </div>
  );
}

export default Checkout;
