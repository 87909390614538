import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import axios from "axios";
import React, { useState } from "react";

const CheckoutForm = ({ clientSecret, checkoutData }) => {
  const stripe = useStripe();
  const elements = useElements();

  const [errorMessage, setErrorMessage] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (elements == null) {
      return;
    }

    // Trigger form validation and wallet collection
    const { error: submitError } = await elements.submit();
    if (submitError) {
      // Show error to your customer
      setErrorMessage(submitError.message);
      return;
    }

    // Create the PaymentIntent and obtain clientSecret from your server endpoint
    // const { data } = await axios.post(
    //   process.env.REACT_APP_BASE_URL + "/user/orders/checkout"
    // );

    // console.log("data", data);

    // const { client_secret: clientSecret } = await res.json();

    const { error } = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      clientSecret,
      confirmParams: {
        return_url: process.env.REACT_APP_BASE_URL + "/orderplaced",
      },
    });

    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      setErrorMessage(error.message);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  return (
    <section className="checkout-area ptb-100">
      <div className="container">
        <div className="d-flex justify-content-center">
          <form
            style={{ width: "100%", maxWidth: "500px" }}
            onSubmit={handleSubmit}
          >
            <PaymentElement />
            <button
              className="default-btn my-3 py-2 px-4"
              type="submit"
              disabled={!stripe || !elements}
            >
              Pay &nbsp;₹ {checkoutData.price}
            </button>

            {/* Show error message to your customers */}
            {errorMessage && <div>{errorMessage}</div>}
          </form>
        </div>
      </div>
    </section>
  );
};

export default CheckoutForm;
