import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { FaStar } from "react-icons/fa";
import { AiOutlineStar } from "react-icons/ai";
import axios from "axios";
import { FaHeart, FaRegHeart } from "react-icons/fa";
import { authenticatelogin } from "../Actions/Action";
import DOMPurify from "dompurify";
import styled from "styled-components";

import {
  getsingleproducts,
  addcarts,
  buynows,
  foryous,
  getreviews,
  addwishlists,
  productspecifications,
} from "../Actions/Action";
import $ from "jquery";
import "./ProductDetails.css";
import { MdDelete } from "react-icons/md";
import { addcart } from "../Actiontypes/Actiontype";
const Token = localStorage.getItem("AuthTok");

const SlideItem = styled.div`
  width: 80px;
  border-radius: 3px;
  overflow: hidden;
  border: 1px solid transparent;
  cursor: pointer;
  transition: border-color 200ms ease-in-out;

  &:hover {
    border-color: #00000051;
  }
`;

const VariantColor = styled.span`
  display: inline-block;
  width: 25px;
  aspect-ratio: 1 / 1;
  margin: 0px !important;

  border: 1px solid transparent;
  cursor: pointer;

  transition: border-color 200ms ease-in-out;
  border-radius: 50%;

  &:hover {
    border-color: #00000051;
  }

  &::before {
    content: "";

    display: inherit;
    width: 50%;
    height: 50%;
    line-height: 1;
    background: ${(prop) => prop.color};
    transform: translate(50%, 50%) scale(1.5);
    border-radius: inherit;
  }
`;

function ProductDetails() {
  let navigate = useNavigate();
  const [user, setUser] = useState({
    email: "",
    password: "",
  });
  const { id } = useParams();
  const dispatch = useDispatch();
  const [passworderror, setPassworderror] = useState("");
  const [load, setLoad] = useState(true);
  const [quantity, setQuantity] = useState(1);
  const [change, setChange] = useState(0);
  const [changeVariant, setChangeVariant] = useState(0);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (user.password.length > 3) {
      dispatch(authenticatelogin(user)).then((res) => {
        if (res.data.data === "Wrong Email/Password. Try Again!") {
          setPassworderror(res.data.data);
        } else {
          window.location.href = "/ProductDetails/" + id;
        }
      });
    } else {
      setPassworderror("password must be  greater than 3 letters");
    }
  };
  const handlechange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };
  useEffect(() => {
    dispatch({
      type: "addcartsuccess",
      data: null,
    });
    window.scrollTo(1000, 0);
  }, []);
  const productspecs = useSelector(
    ({ productspecifications }) => productspecifications.payload
  );
  const review = useSelector(({ getreviews }) => getreviews.payload);
  const sproduct = useSelector(
    ({ getsingleproducts }) => getsingleproducts.payload
  );
  const cartmessage = useSelector(({ addcarts }) => addcarts.payload);

  const you = useSelector(({ foryous }) => foryous.payload);
  const buy = useSelector(({ buynows }) => buynows.payload);
  //product list
  const proid = id;
  const [pid, setPid] = useState({
    productId: proid,
  });
  //product list
  const [cartid, getCardid] = useState({
    variantColor: "",
    variantId: "",
    units: 0,
  });
  const handleClick = () => {
    cartid.variantId = sproduct[0]?.id;
    cartid.units = quantity;
    cartid.variantColor = JSON.parse(sproduct[changeVariant]?.variantColor)[
      change
    ];
    dispatch(addcarts(cartid));
  };

  const Clicktobuy = () => {
    cartid.variantId = sproduct[0]?.id;
    cartid.units = quantity;
    cartid.variantColor = JSON.parse(sproduct[changeVariant]?.variantColor)[
      change
    ];

    dispatch(buynows(cartid)).then((res) => {
      navigate("/cart");
    });
    // .then((res) => {
    //   var config = {
    //     root: "",
    //     style: {
    //       bodyBackgroundColor: "#fafafb",
    //       bodyColor: "",
    //       themeBackgroundColor: "#0FB8C9",
    //       themeColor: "#ffffff",
    //       headerBackgroundColor: "#284055",
    //       headerColor: "#ffffff",
    //       errorColor: "",
    //       successColor: "",
    //       card: {
    //         padding: "",
    //         backgroundColor: "",
    //       },
    //     },
    //     data: {
    //       orderId: res.data.data.orderId,
    //       token: res.data.data.txnToken,
    //       tokenType: "TXN_TOKEN",
    //       amount: res.data.data.discountPrice /* update amount */,
    //     },
    //     payMode: {
    //       labels: {},
    //       filter: {
    //         exclude: [],
    //       },
    //       order: ["CC", "DC", "NB", "UPI", "PPBL", "PPI", "BALANCE"],
    //     },
    //     website: "WEBSTAGING",
    //     flow: "DEFAULT",
    //     merchant: {
    //       mid: "sKxlKi69872184882034",
    //       redirect: false,
    //     },
    //     handler: {
    //       transactionStatus: function transactionStatus(paymentStatus) {},
    //       notifyMerchant: function notifyMerchant(eventName, data) {},
    //     },
    //   };
    //   if (window.Paytm && window.Paytm.CheckoutJS) {
    //     window.Paytm.CheckoutJS.init(config)
    //       .then(function onSuccess() {
    //         window.Paytm.CheckoutJS.invoke();
    //       })
    //       .catch(function onError(error) {});
    //   }
    // });
  };

  useEffect(() => {
    Promise.allSettled([
      dispatch(getsingleproducts(pid)),
      dispatch(productspecifications(pid)),
      dispatch(foryous()),
      dispatch(getreviews(pid)),
    ]).then((res) =>
      setLoad(false)(
        setTimeout(() => {
          tabss();
        }, 1000)
      )
    );
  }, [pid]);

  // Tabs List
  const tabss = () => {
    (function () {
      $(".tab ul.tabs-list")
        .addClass("active")
        .find("> li:eq(0)")
        .addClass("current");
      $(".tab ul.tabs-list li").on("click", function (g) {
        var tab = $(this).closest(".tab"),
          index = $(this).closest("li").index();
        tab.find("ul.tabs-list > li").removeClass("current");
        $(this).closest("li").addClass("current");
        tab
          .find(".tab_content")
          .find("div.tabs_item")
          .not("div.tabs_item:eq(" + index + ")")
          .slideUp();
        tab
          .find(".tab_content")
          .find("div.tabs_item:eq(" + index + ")")
          .slideDown();
        g.preventDefault();
      });
    })();

    // Tabs
    (function () {
      $(".tab ul.tabs")
        .addClass("active")
        .find("> li:eq(0)")
        .addClass("current");
      $(".tab ul.tabs li a").on("click", function (g) {
        var tab = $(this).closest(".tab"),
          index = $(this).closest("li").index();
        tab.find("ul.tabs > li").removeClass("current");
        $(this).closest("li").addClass("current");
        tab
          .find(".tab_content")
          .find("div.tabs_item")
          .not("div.tabs_item:eq(" + index + ")")
          .slideUp();
        tab
          .find(".tab_content")
          .find("div.tabs_item:eq(" + index + ")")
          .slideDown();
        g.preventDefault();
      });
    })();
  };

  return load ? (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "400px",
        }}
      >
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </>
  ) : (
    <div>
      <div class="page-title-area">
        <div class="container">
          {/* <div class="page-title-content" style={{display:"flex",justifyContent:"space-between"}}>
            <h2>Products Details</h2>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/shop">Shop</Link>
              </li>
              <li>Products Details </li>
            </ul>
          </div> */}
        </div>
      </div>
      <section class="product-details-area pt-100 pb-70">
        {Array.isArray(sproduct) && sproduct.length > 0 ? (
          <div class="container">
            <div class="row ">
              <div class="col-lg-6 col-md-12">
                {/* slide item views */}
                <div
                  style={{
                    maxWidth: "100%",
                    aspectRatio: "3 / 2",
                    boxShadow: "0 0 1px 0 #a9a9a9",
                    borderRadius: "3px",
                  }}
                >
                  <img
                    src={
                      process.env.REACT_APP_BASE_URL +
                      JSON.parse(sproduct[changeVariant]?.variantImage)[change]
                    }
                    alt="slide view"
                    style={{
                      display: "block",
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                </div>

                {/* slide item list*/}
                <div
                  class="mt-4"
                  style={{
                    display: "flex",
                    gap: "20px",
                    // maxWidth: "100%",
                    overflowX: "auto",
                  }}
                >
                  {JSON.parse(sproduct[changeVariant]?.variantImage).map(
                    (data, i) => (
                      <SlideItem
                        style={{
                          aspectRatio: "3 / 2",
                          maxWidth: "80px",
                          borderColor: change === i ? "#000000" : "",
                        }}
                        onClick={() => setChange(i)}
                      >
                        <div
                          style={{
                            padding: "3px",
                            width: "100%",
                            height: "100%",
                          }}
                        >
                          <img
                            alt="slide item"
                            style={{
                              display: "inline-block",
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                            }}
                            src={process.env.REACT_APP_BASE_URL + data}
                          />
                        </div>
                      </SlideItem>
                    )
                  )}
                </div>
              </div>

              <div class="col-lg-6 col-md-12">
                <div class="product-details-desc">
                  <h3
                    style={{
                      fontFamily: "sans-serif",
                      textTransform: "capitalize",
                    }}
                  >
                    {sproduct[changeVariant]?.productName}
                    {/* <span style={{ textTransform: "capitalize" }}>
                      ({sproduct[changeVariant]?.variantName})
                    </span> */}
                  </h3>
                  <div className="mt-2"></div>

                  <div className="mt-3 mb-3">
                    {Array.isArray(sproduct)
                      ? sproduct?.map((data, index) => (
                          <button
                            onClick={() => setChangeVariant(index)}
                            className="btn btn-light "
                            style={{
                              marginRight: "10px",
                              textTransform: "capitalize",
                              borderRadius: "0px",
                              textAlign: "center",
                              padding: "0",
                              verticalAlign: "middle",
                            }}
                          >
                            {data?.variantName}
                          </button>
                        ))
                      : false}
                  </div>
                  <div class="price">
                    <span class="new-price" style={{ fontSize: "35px" }}>
                      ₹
                      {
                        JSON.parse(sproduct[changeVariant]?.discountPrice)[
                          change
                        ]
                      }
                    </span>
                    <span
                      style={{ marginLeft: "10px", fontSize: "20px" }}
                      class="old-price"
                    >
                      ₹
                      {JSON.parse(sproduct[changeVariant]?.actualPrice)[change]}
                    </span>
                  </div>

                  <div class="product-add-to-cart">
                    <div class="input-counter">
                      <span
                        onClick={() => {
                          return quantity > 1
                            ? setQuantity(quantity - 1)
                            : false;
                        }}
                        class="minus-btn"
                      >
                        <i class="bx bx-minus"></i>
                      </span>
                      <input
                        type="text"
                        min="1"
                        value={quantity}
                        onChange={(e) => setQuantity(e.target.value)}
                        disabled
                      />
                      <span
                        class="plus-btn"
                        onClick={() => {
                          setQuantity(quantity + 1);
                        }}
                      >
                        <i class="bx bx-plus"></i>
                      </span>
                    </div>
                    {Token ? (
                      <button
                        type="submit"
                        class="default-btn"
                        onClick={handleClick}
                      >
                        Add to cart
                        <span></span>
                      </button>
                    ) : (
                      <button
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                        class="default-btn"
                      >
                        Add to cart
                        <span></span>
                      </button>
                    )}
                  </div>
                  <div
                    class="modal fade"
                    id="exampleModal"
                    tabindex="-1"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                  >
                    <div class="modal-dialog modal-dialog-centered">
                      <div class="modal-content">
                        <div class="modal-header">
                          <h5 class="modal-title" id="exampleModalLabel">
                            Login
                          </h5>
                          <button
                            type="button"
                            class="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          ></button>
                        </div>
                        <div class="modal-body">
                          <form onSubmit={handleSubmit}>
                            <div class="row">
                              <div class="col-lg-12 mt-3 mb-3">
                                <div class="form-group">
                                  <input
                                    type="email"
                                    class="form-control"
                                    placeholder="Email"
                                    name="email"
                                    style={{ borderRadius: "0px" }}
                                    onChange={handlechange}
                                    value={user.email}
                                    required
                                  />
                                </div>
                              </div>
                              <div class="col-lg-12 mt-3">
                                <div class="form-group">
                                  <input
                                    type="password"
                                    class="form-control"
                                    placeholder="Password"
                                    name="password"
                                    style={{ borderRadius: "0px" }}
                                    onChange={handlechange}
                                    value={user.password}
                                    required
                                  />
                                  <span
                                    style={{
                                      color: "brown",
                                      marginLeft: "20px",
                                    }}
                                  >
                                    {passworderror}
                                  </span>
                                </div>
                              </div>
                              <div class="col-lg-12 mb-3">
                                <p class="forgot-password">
                                  <a href="/Register">
                                    Don't you have an account? <b>Register</b>
                                  </a>
                                </p>
                              </div>
                              <div class="col-lg-12">
                                <div class="send-btn">
                                  <button type="submit" class="default-btn">
                                    Login
                                    <span></span>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mt-3">
                    {cartmessage ? (
                      <div
                        class="alert alert-warning alert-dismissible fade show"
                        role="alert"
                        mt-3
                      >
                        {cartmessage}
                        <button
                          onClick={() => {
                            dispatch({
                              type: addcart.add.success,
                              data: null,
                            });
                          }}
                          type="button"
                          class="btn-close"
                          data-bs-dismiss="alert"
                          aria-label="Close"
                        ></button>
                      </div>
                    ) : (
                      false
                    )}
                  </div>

                  <div class="custom-payment-options">
                    <span>Color</span>
                    <div style={{ display: "flex", gap: "10px" }}>
                      {JSON.parse(sproduct[changeVariant]?.variantColor).map(
                        (data, i) => (
                          <VariantColor
                            color={data}
                            style={{
                              borderColor: change === i ? "#000000" : "",
                            }}
                            onClick={() => setChange(i)}
                          ></VariantColor>
                        )
                      )}
                    </div>
                  </div>
                  <div class="buy-checkbox-btn">
                    <div class="item"></div>
                    <div class="item">
                      <a class="btn btn-light" onClick={Clicktobuy}>
                        Buy Now
                      </a>
                      {buy ? (
                        <div
                          class="alert alert-warning alert-dismissible fade show"
                          role="alert"
                          mt-3
                        >
                          {buy}
                          <button
                            type="button"
                            class="btn-close"
                            data-bs-dismiss="alert"
                            aria-label="Close"
                          ></button>
                        </div>
                      ) : (
                        false
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-12 col-md-12">
                <div class="tab products-details-tab">
                  <div class="row">
                    <div class="col-lg-12 col-md-12">
                      <ul class="tabs">
                        <li>
                          <a>Description</a>
                        </li>
                        <li>
                          <a>More Info</a>
                        </li>
                        <li>
                          <a>Specification</a>
                        </li>
                        <li>
                          <a>Reviews</a>
                        </li>
                      </ul>
                    </div>
                    <div class="col-lg-12 col-md-12">
                      <div class="tab_content">
                        <div class="tabs_item" id="desc">
                          <div class="products-details-tab-content">
                            <p>
                              {productspecs?.productDescription ? (
                                productspecs?.productDescription
                              ) : (
                                <center>
                                  <b style={{ color: "black" }}>
                                    No Product Description Found
                                  </b>
                                </center>
                              )}
                            </p>
                          </div>
                        </div>
                        <div class="tabs_item" id="more">
                          <div class="products-details-tab-content">
                            {productspecs?.moreInfo ? (
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: DOMPurify.sanitize(
                                    productspecs?.moreInfo
                                  ),
                                }}
                              ></p>
                            ) : (
                              <p>
                                <center>
                                  <b style={{ color: "black" }}>
                                    No Information Found
                                  </b>
                                </center>
                              </p>
                            )}
                          </div>
                        </div>

                        <div class="tabs_item" id="spec">
                          {Array.isArray(productspecs?.productSpecification) ? (
                            <div class="products-details-tab-content">
                              <ul class="additional-information">
                                {JSON.parse(
                                  productspecs?.productSpecification
                                )?.map((SP) => (
                                  <li>
                                    <span>{SP?.productTitle}</span>
                                    {SP?.productAnswer}
                                  </li>
                                ))}
                              </ul>
                            </div>
                          ) : (
                            <center>
                              <h4>No Specification Found</h4>
                            </center>
                          )}
                        </div>

                        <div class="tabs_item" id="review">
                          <div class="products-details-tab-content">
                            {/* test */}
                            <div
                              className="container"
                              style={{ diaplay: "flex" }}
                            >
                              {Array.isArray(review) ? (
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-evenly",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <h1
                                      style={{
                                        fontSize: "150px",
                                        fontFamily: "Source Sans Pro",
                                      }}
                                    >
                                      {review?.reviewData?.overallRatings}
                                    </h1>
                                  </div>
                                  <div className="" style={{ width: "500px" }}>
                                    <div class="">
                                      <div className="mt-3 mb-3">
                                        <div>
                                          <span> Excellent </span>
                                          <div class="progress">
                                            <div
                                              class="progress-bar"
                                              role="progressbar"
                                              style={{
                                                backgroundColor: "green",
                                                borderRadius: "30px",
                                                width:
                                                  review?.reviewData
                                                    ?.excellent + "%",
                                              }}
                                              aria-valuenow="29"
                                              aria-valuemin="0"
                                              aria-valuemax="0"
                                            ></div>
                                          </div>
                                        </div>
                                        <div>
                                          <span> Best </span>
                                          <div class="progress">
                                            <div
                                              class="progress-bar"
                                              role="progressbar"
                                              style={{
                                                backgroundColor: "lightgreen",
                                                borderRadius: "30px",
                                                width:
                                                  review?.reviewData?.best +
                                                  "%",
                                              }}
                                              aria-valuenow="29"
                                              aria-valuemin="0"
                                              aria-valuemax="0"
                                            ></div>
                                          </div>
                                        </div>

                                        <div>
                                          <span> Good </span>
                                          <div class="progress">
                                            <div
                                              class="progress-bar"
                                              role="progressbar"
                                              style={{
                                                backgroundColor: "yellow",
                                                borderRadius: "30px",
                                                width:
                                                  review?.reviewData?.good +
                                                  "%",
                                              }}
                                              aria-valuenow="29"
                                              aria-valuemin="0"
                                              aria-valuemax="0"
                                            ></div>
                                          </div>
                                        </div>
                                        <div>
                                          <span> Below Average </span>
                                          <div class="progress">
                                            <div
                                              class="progress-bar"
                                              role="progressbar"
                                              style={{
                                                backgroundColor: "orange",
                                                borderRadius: "30px",
                                                width:
                                                  review?.reviewData?.poor +
                                                  "%",
                                              }}
                                              aria-valuenow="29"
                                              aria-valuemin="0"
                                              aria-valuemax="0"
                                            ></div>
                                          </div>
                                        </div>
                                        <div>
                                          <span> Poor </span>
                                          <div class="progress">
                                            <div
                                              class="progress-bar"
                                              role="progressbar"
                                              style={{
                                                backgroundColor: "red",
                                                borderRadius: "30px",
                                                width:
                                                  review?.reviewData?.verypoor +
                                                  "%",
                                              }}
                                              aria-valuenow="29"
                                              aria-valuemin="0"
                                              aria-valuemax="0"
                                            ></div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                false
                              )}
                              {/* test */}
                              {Array.isArray(review) ? (
                                <p style={{ textAlign: "center" }}>
                                  Based on {review?.reviewData?.noOfRatings}
                                  reviews
                                </p>
                              ) : (
                                false
                              )}
                              {Array.isArray(review?.reviews) &&
                              review?.reviews.length !== 0 ? (
                                review?.reviews.map((R) => (
                                  <div class="product-review-form">
                                    <div class="review-comments">
                                      <div class="review-item">
                                        <div style={{ display: "flex" }}>
                                          <img
                                            src={
                                              process.env.REACT_APP_BASE_URL +
                                              R?.customerImage
                                            }
                                            style={{
                                              width: "40px",
                                              height: "40px",
                                              marginRight: "10px",
                                              borderRadius: "30px",
                                            }}
                                          />{" "}
                                          <h5
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              marginLeft: "5px",
                                            }}
                                          >
                                            {R?.customerName}
                                          </h5>
                                        </div>
                                        <span
                                          class="rating ml-3 mt-3"
                                          style={{ display: "flex" }}
                                        >
                                          {Array.from(
                                            { length: 5 },
                                            (data, i) =>
                                              i < R?.rating ? (
                                                <span style={{ margin: "2px" }}>
                                                  <FaStar
                                                    style={{
                                                      color: "orange",
                                                    }}
                                                  />
                                                </span>
                                              ) : (
                                                <span style={{ margin: "1px" }}>
                                                  <AiOutlineStar />
                                                </span>
                                              )
                                          )}
                                          <span
                                            style={{
                                              marginLeft: "10px",
                                              marginTop: "5px",
                                            }}
                                          >
                                            {" "}
                                            {R?.rating} out of 5{" "}
                                          </span>
                                        </span>
                                        <span>
                                          <strong> </strong>
                                        </span>
                                        <p style={{ marginLeft: "10px" }}>
                                          {R?.review}
                                        </p>
                                        <a class="review-report-link">
                                          <strong>{R.createdAt}</strong>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                ))
                              ) : (
                                <center>
                                  <h4>{review}</h4>
                                </center>
                              )}
                              {/* review */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          false
        )}

        <div class="related-products">
          <div class="container">
            {Token ? (
              <div class="products-title">
                <h2>Related Products</h2>
              </div>
            ) : (
              false
            )}
            <div class="row">
              {Array.isArray(you) && you.length !== 0
                ? you
                    .filter((y) => y?.discountPrice >= 1)
                    ?.map((y) => (
                      <div class="col-lg-4 col-md-6">
                        <div class="products-item ">
                          <div class="image">
                            <a>
                              <img
                                src={
                                  process.env.REACT_APP_BASE_URL +
                                  y?.productImage
                                }
                                alt={y?.productName}
                              />
                            </a>
                            <ul class="social" style={{ marginTop: "-60px" }}>
                              <li>
                                <a
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    fontSize: "20px",
                                  }}
                                  onClick={() => {
                                    setLoad(true);
                                    dispatch(
                                      addwishlists({ productId: y?.id })
                                    ).then((res) => {
                                      setLoad(false);
                                    });
                                  }}
                                >
                                  {load ? (
                                    <></>
                                  ) : y?.favourites === 0 ? (
                                    <span>
                                      <FaRegHeart />
                                    </span>
                                  ) : (
                                    <span>
                                      <FaHeart />
                                    </span>
                                  )}
                                </a>
                              </li>
                            </ul>
                            <div class="shop-btn">
                              <Link
                                to={"/productDetails/" + y?.id}
                                style={{ cursor: "pointer" }}
                                class="default-btn"
                                onClick={() => {
                                  setLoad(true);
                                  setPid({ productId: y?.id });
                                }}
                              >
                                View Product
                              </Link>
                            </div>
                          </div>
                          <div class="content p-4">
                            <h3>{y?.productName}</h3>
                            <span>₹{y?.discountPrice}</span>
                          </div>
                        </div>
                      </div>
                    ))
                : false}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default ProductDetails;
