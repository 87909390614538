import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { getprofiles, updateprofiles } from "../Actions/Action";
function UpdateProfile() {
  const [load, setLoad] = useState(true);
  const [user, setUser] = useState({
    image: "",
    username: "",
    alaisname: "",
    dob: "",
    sex: "",
  });
  const [prevImg, setPrevImg] = useState("/assets/img/profilepictrue.png");
  const [selectedImage, setSelectedImage] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getprofiles()).then(
      (res) => {
        setUser({
          ...user,
          username: res.data.data.userName,
          alaisname: res.data.data.alaisName,
          dob: res.data.data.dob,
          sex: res.data.data.gender,
        });
        if (res.data.data.profilePic) setPrevImg(res.data.data.profilePic);
      },
      dispatch(getprofiles()).then((res) => setLoad(false))
    );
  }, []);
  useEffect(() => {
    window.scrollTo(1000, 0);
  }, []);
  const imageChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setPrevImg(URL.createObjectURL(e.target.files[0]));
      setUser({ ...user, [e.target.name]: e.target.files[0] });
    }
  };
  const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();
    Object.entries(user).map(([key, value]) => formData.append(key, value));
    dispatch(updateprofiles(formData)).then(() => {
      navigate("/profile");
    });
  };
  return load ? (
    <div
      style={{
        height: "400px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  ) : (
    <div>
      <div class="page-title-area">
        <div class="container">
          <div
            class="page-title-content"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <h2>Update Profile</h2>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>Update Profile</li>
            </ul>
          </div>
        </div>
      </div>

      <section class="register-area ptb-100">
        <div class="container col-lg-4 d-flex justify-content-start">
          <div class="register-form">
            <div class="register-in-title">
              <center>
                <h3>Update Profile</h3>
              </center>
            </div>
            <form onSubmit={handleSubmit}>
              <div class="row">
                {/* <div class="form-group">
                  <div>
                    <div style={styles.container}>
                      <div style={styles.preview}>
                        <img src={prevImg} style={styles.image} alt="Thumb" />
                      </div>

                      <input
                        accept="image/*"
                        type="file"
                        name="image"
                        onChange={imageChange}
                        style={{
                          marginTop: "20px",
                          marginLeft: "85px",
                          width: "30px",
                          position: "absolute",
                          borderRadius: "50%",
                          opacity: "0",
                        }}
                      />
                      <div>
                        <span className="imageedit" style={{ color: "white" }}>
                          <BsPencil />
                        </span>
                      </div>
                    </div>
                  </div>
                </div> */}

                <div class="form-group">
                  <label className="my-3" style={{ fontWeight: "bold" }}>
                    Username
                  </label>
                  <input
                    type="text"
                    name="username"
                    onChange={handleChange}
                    value={user.username}
                    class="form-control"
                    placeholder="User Name"
                    required
                  />
                </div>
                {/* <div class="form-group">
                  <label className="mb-3" style={{ fontWeight: "bold" }}>
                    Alias Name
                  </label>
                  <input
                    type="text"
                    name="alaisname"
                    value={user.alaisname}
                    onChange={handleChange}
                    class="form-control"
                    placeholder="Alias Name"
                    required
                  />
                </div> */}
                <div class="form-group">
                  <label className="mb-3" style={{ fontWeight: "bold" }}>
                    DOB
                  </label>
                  <input
                    type="Date"
                    name="dob"
                    value={user.dob}
                    onChange={handleChange}
                    class="form-control"
                    required
                  />
                </div>

                <label className="mb-3" style={{ fontWeight: "bold" }}>
                  Gender
                </label>
                <select
                  class="form-select"
                  value={user.sex}
                  onChange={handleChange}
                  name="sex"
                  aria-label="Default select example"
                  style={{
                    maxWidth: "529px",
                    marginLeft: "13px",
                    height: "49px",
                  }}
                >
                  <option selected> Choose Gender</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="others">Transgender</option>
                </select>
                <div class="col-lg-12">
                  <div class="send-btn">
                    <button type="submit" className="default-btn">
                      Update Profile
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </div>
  );
}

export default UpdateProfile;
const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: "0px",
    paddingBottom: "0px",
  },
  preview: {
    display: "flex",
    flexDirection: "column",
  },
  image: { width: "100px", height: "100px", borderRadius: "50%" },
  // editimage:{
  //   color: "white",
  //   background: "black",
  //   width: "30px",
  //   padding: "7px",
  //   borderRadius: "50%",
  //   display: "flex",
  //   marginTop: "-70px",
  //   marginLeft: "230px",
  // }
};
