import axios from "axios";
export function setHeaderToken(token) {
  if (token) {
    axios.defaults.headers.common["authtoken"] = `${token}`;
  } else {
    delete axios.defaults.headers.common["  "];
  }
}
export function axiosInstance(method, path, data, params, header) {
  return axios({
    url: path,
    method: method.toLowerCase(),
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: { "Content-Type": "application/json", ...(header ? header : {}) },
    params: params,
    data: data,
    timeout: 0,
  });
}
