import React, { useEffect, useState } from "react";
import { getprofiles } from "../Actions/Action";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import "./Profile.css";
import Table from "react-bootstrap/Table";
import AddressForm from "./AddressForm";
import Order from "./card/Order";
import Card from "react-bootstrap/Card";
import ListReviews from "./ListReviews";
import { logouts } from "../Actions/Action";
import styled from "styled-components";
import $ from "jquery";
import { PiUserCircleThin } from "react-icons/pi";
import { HiPencilAlt } from "react-icons/hi";

import { AiOutlineSmile } from "react-icons/ai";
import { LuPhone } from "react-icons/lu";
import { AiOutlineMail } from "react-icons/ai";
import { BsCalendar2Date } from "react-icons/bs";
import { BsGenderAmbiguous } from "react-icons/bs";
import { RiMailCheckLine } from "react-icons/ri";
import { TbPhoneCheck } from "react-icons/tb";

const ProfilIcons = {
  userName: <AiOutlineSmile />,
  phone: <LuPhone />,
  email: <AiOutlineMail />,
  dob: <BsCalendar2Date />,
  gender: <BsGenderAmbiguous />,
  isMailVerified: <RiMailCheckLine />,
  isPhoneVerified: <TbPhoneCheck />,
};

const ProfileText = styled.p`
  display: flex;
  align-items: center;
  gap: 10px;
  line-height: 1;
  padding: 0;
  margin: 0 !important;
  color: #000;

  svg {
    color: gray;
    width: 25px;
    height: 25px;
  }
`;

function Profile() {
  const [load, setLoad] = useState(true);
  const dispatch = useDispatch();

  const navigate = useNavigate();
  useEffect(() => {
    dispatch(getprofiles())
      .then((res) => {})
      .catch((res) => {})
      .finally(() => {
        setLoad(false);
        // to load tabs jquery code
        tabss();
      });
  }, []);
  const profilelist = useSelector(({ getprofiles }) => getprofiles.payload);

  const styles = {
    textAlign: "center",
    textTransform: "capitalize",
    verticalAlign: "middle",
  };
  const styless = {
    textAlign: "center",
    verticalAlign: "middle",
  };
  const logout = () => {
    dispatch(logouts());
    localStorage.removeItem("AuthTok");
    window.location.href = "/";
  };
  useEffect(() => {
    window.scrollTo(1000, 0);
  }, []);

  // Tabs List, jquery load
  const tabss = () => {
    (function () {
      const params = new URLSearchParams(window.location.search);
      const tabId = params.get("tabId");

      if (tabId) {
        $(".tab ul.tabs")
          .addClass("active")
          .find(`> li:eq(${tabId})`)
          .addClass("current");

        var tab = $(".tab ul.tabs li").closest(".tab");

        tab
          .find(".tab_content")
          .find("div.tabs_item")
          .not("div.tabs_item:eq(" + tabId + ")")
          .slideUp();

        tab
          .find(".tab_content")
          .find("div.tabs_item:eq(" + tabId + ")")
          .slideDown();
      } else {
        // to select tab heading
        $(".tab ul.tabs")
          .addClass("active")
          .find("> li:eq(0)")
          .addClass("current");
      }

      // to select tab content
      $(".tab ul.tabs li a").on("click", function (g) {
        var tab = $(this).closest(".tab"),
          index = $(this).closest("li").index();

        tab.find("ul.tabs > li").removeClass("current");

        $(this).closest("li").addClass("current");

        tab
          .find(".tab_content")
          .find("div.tabs_item")
          .not("div.tabs_item:eq(" + index + ")")
          .slideUp();

        tab
          .find(".tab_content")
          .find("div.tabs_item:eq(" + index + ")")
          .slideDown();

        g.preventDefault();
      });
    })();
  };

  return load ? (
    <div
      style={{
        height: "400px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  ) : (
    <div>
      <div class="page-title-area">
        <div class="container">
          <div
            class="page-title-content"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <h2>Profile</h2>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>Profile</li>
              <li style={{ cursor: "pointer" }} onClick={logout}>
                Logout
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-lg-12 col-md-12 container" style={{ marginTop: "-28px" }}>
        <div class="tab products-details-tab">
          <div class="row">
            <div class="col-lg-12 col-md-12">
              <ul class="tabs">
                <li>
                  <a>Profile</a>
                </li>
                <li>
                  <a>Address</a>
                </li>
                <li>
                  <a>Orders </a>
                </li>
                <li>
                  <a>Reviews</a>
                </li>
              </ul>
            </div>

            <div class="col-lg-12 col-md-12 mt-5">
              <div class="container tab_content">
                <div class="tabs_item" id="desc">
                  <div class="products-details-tab-content">
                    <div
                      style={{
                        margin: "0 auto",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        gap: "30px",
                      }}
                    >
                      <div>
                        <PiUserCircleThin size={50} />
                        <HiPencilAlt
                          cursor="pointer"
                          title="Edit"
                          style={{ marginLeft: "10px" }}
                          onClick={() =>
                            navigate(`/UpdateProfile/${profilelist?.id}`)
                          }
                        />
                      </div>

                      {/* detail list */}
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "10px",
                        }}
                      >
                        {Object.entries(ProfilIcons).map(([name, icon]) => {
                          const verifyStatus = {
                            yes: "Verified",
                            no: "Not Verified",
                          };

                          const value = profilelist[name];
                          const label = name?.includes("Verified")
                            ? verifyStatus[value]
                            : value;

                          return (
                            <ProfileText
                              style={{
                                textTransform:
                                  name === "email" ? "lowercase" : "capitalize",
                              }}
                            >
                              {icon} {label}
                            </ProfileText>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="tabs_item" id="more">
                  <div class="products-details-tab-content">
                    <AddressForm />
                  </div>
                </div>
                <div class="tabs_item" id="spec">
                  <Order />
                </div>
                <div class="tabs_item" id="review">
                  <ListReviews />
                </div>
              </div>
            </div>

            {/* 
            
            
            
            */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Profile;
