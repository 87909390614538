import React, { useState, useEffect } from "react";
import "./Order.css";
import { getorders } from "../../Actions/Action";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import orderimage from "./orders.svg";
function Order() {
  const dispatch = useDispatch();
  const [load, setLoad] = useState(true);
  useEffect(() => {
    dispatch(getorders())
      .then((res) => setLoad(false))
      .catch((res) => setLoad(false));
  }, []);
  useEffect(() => {
    window.scrollTo(1000, 0);
  }, []);
  const order = useSelector(({ getorders }) => getorders.payload);
  const styles = {
    textAlign: "center",
    color: "black",
  };
  return load ? (
    <div
      style={{
        height: "400px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  ) : (
    <div>
      <div>
        <div class="container mt-5 mb-5 pt-5 pb-5">
          <div class="cart-table table-responsive">
            <table class="table table-bordered">
              {Array.isArray(order) && order.length !== 0 ? (
                <thead>
                  <tr>
                    <th scope="col" style={styles}>
                      S.No
                    </th>

                    <th scope="col" style={styles}>
                      Order Id
                    </th>
                    <th scope="col" style={styles}>
                      Order Image
                    </th>
                    <th scope="col" style={styles}>
                      Order Status
                    </th>
                    <th scope="col" style={styles}>
                      Order Date
                    </th>
                  </tr>
                </thead>
              ) : (
                false
              )}
              {Array.isArray(order) && order.length !== 0 ? (
                order?.map((C, i) => (
                  <tbody>
                    <tr>
                      <td style={styles}>
                        <a>{i + 1}</a>
                      </td>

                      <td style={styles}>
                        <Link
                          to={"/Getorderdetails/" + C.orderId}
                          style={{ textTransform: "capitalize" }}
                        >
                          {C.orderId}
                        </Link>
                      </td>
                      <td style={styles}>
                        <span>
                          <img
                            style={{ width: "80px" }}
                            src={
                              process.env.REACT_APP_BASE_URL +
                              C.variantImages[0]
                            }
                            alt=""
                          />
                        </span>
                      </td>
                      <td style={styles}>
                        <span style={{ textTransform: "capitalize" }}>
                          {C.orderStatus}
                        </span>
                      </td>

                      <td style={styles}>
                        <span>{C.timestamp}</span>
                      </td>
                    </tr>
                  </tbody>
                ))
              ) : (
                <center>
                  <img
                    src={orderimage}
                    className="mt-5 mb-5 pb-5 pt-5"
                    style={{ width: "25%" }}
                  />
                  <h3> No Orders Found</h3>{" "}
                </center>
              )}
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Order;
