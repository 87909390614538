import React, { useState } from "react";
import "./Search.css";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { useNavigate } from "react-router";
import styled from "styled-components";

const SearchInputField = styled.input`
  margin: 0;
  padding: 8px;
  border-left: 2px solid transparent;
  height: 43px;
  width: 100%;

  @media (max-width: 768px) {
    max-height: 33px;
  }
`;

const SearchIcon = styled.i`
  &::before {
    font-size: 25px;
    padding-left: 8px;
    padding-right: 6px;

    @media (max-width: 768px) {
      font-size: 20px;
    }
  }
`;

const SearchButton = styled.button`
  background: #0a0a0a;
  color: #fff;
  left: 93.1%;
  width: fit-content;
  outline: none;
  border: none;
  padding-left: 20px;
  padding-right: 20px;

  height: 43px;
  @media (max-width: 768px) {
    max-height: 33px;
  }
`;

const SearchWrapper = styled.div`
  border: 2px solid #080808;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 90%;
  max-width: 700px;
  margin: 0 auto;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    max-width: 500px;

    margin-top: 30px;
  }
`;

const SearchForm = styled.form`
  width: 100%;
`;

function Search() {
  const navigate = useNavigate();
  const [search, setSearch] = useState("");

  const handleClick = (e) => {
    e.preventDefault();
    navigate(`${"/SearchProducts/" + search}`);
  };
  return (
    <SearchForm className="my-3" onSubmit={handleClick}>
      <SearchWrapper>
        <SearchIcon className="bx bx-search" />
        <SearchInputField
          type="text"
          onChange={(e) => setSearch(e.target.value)}
          placeholder="Search Products"
          required
        />
        <OverlayTrigger overlay={<Tooltip id="tooltip">Search</Tooltip>}>
          <SearchButton type="submit">Search</SearchButton>
        </OverlayTrigger>
      </SearchWrapper>
    </SearchForm>
  );
}

export default Search;
