import styled from "styled-components";

export const Logo = styled.img`
  display: inline-block;
  width: 110px !important;

  @media (min-width: 768px) {
    width: 120px !important;
  }

  @media (min-width: 1200px) {
    width: 150px !important;
  }
`;
