import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getwishlists, addwishlists } from "../Actions/Action";
import wishlistimage from "../wishlist.svg";
import NumberFormat from "react-number-format";

function Wishlist() {
  const [load, setLoad] = useState(true);
  const [render, setRender] = useState(true);
  const dispatch = useDispatch();
  const wish = useSelector(({ getwishlists }) => getwishlists.payload);
  useEffect(() => {
    dispatch(getwishlists())
      .then((res) => setLoad(false))
      .catch((res) => setLoad(false));
  }, [render]);

  useEffect(() => {
    window.scrollTo(1000, 0);
  }, []);

  const weight = {
    textTransform: "Capitalize",
    color: "black",
    textAlign: "center",
  };

  return load ? (
    <div
      style={{
        height: "400px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  ) : (
    <div>
      <div className="page-title-area">
        <div className="container">
          <div
            className="page-title-content"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <h2>Wishlist</h2>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>Wishlist</li>
            </ul>
          </div>
        </div>
      </div>

      <section className="cart-area ptb-100 mt-5 mb-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <form>
                <div className="cart-table table-responsive">
                  <table className="table table-bordered">
                    <thead>
                      {Array.isArray(wish.data) && wish?.data?.length !== 0 ? (
                        <tr>
                          <th scope="col" style={weight}>
                            S.No
                          </th>
                          <th scope="col" style={{ textAlign: "center" }}>
                            Name
                          </th>
                          <th scope="col" style={{ textAlign: "center" }}>
                            Product
                          </th>
                          <th scope="col" style={{ textAlign: "center" }}>
                            Unit Price
                          </th>
                          <th scope="col" style={{ textAlign: "center" }}>
                            Action
                          </th>
                        </tr>
                      ) : (
                        false
                      )}
                    </thead>
                    {Array.isArray(wish.data) && wish?.data?.length !== 0 ? (
                      wish?.data?.map((w, i) => (
                        <tbody>
                          <tr>
                            <td style={weight} className="product-name">
                              <a style={{ color: "black" }}>{i + 1}</a>
                            </td>
                            <td style={weight} className="product-name">
                              <Link
                                to={"/ProductDetails/" + w.id}
                                style={{ color: "black" }}
                              >
                                {w.productName}
                              </Link>
                            </td>
                            <td style={weight} className="product-thumbnail">
                              <a>
                                <Link to={"/ProductDetails/" + w.id}>
                                  <img
                                    src={
                                      process.env.REACT_APP_BASE_URL +
                                      w.productImage
                                    }
                                    alt="item"
                                  />
                                </Link>
                              </a>
                            </td>
                            <td style={weight} className="product-price">
                              <span className="unit-amount">
                                {typeof w.discountPrice == "number" ? (
                                  <>₹{w.discountPrice}</>
                                ) : (
                                  <span
                                    style={{ color: "red", fontWeight: "bold" }}
                                  >
                                    Product Currently Not Available
                                  </span>
                                )}
                              </span>
                            </td>
                            <td style={weight}>
                              <a
                                onClick={() => {
                                  setLoad(true);
                                  dispatch(
                                    addwishlists({ productId: w.id })
                                  ).then(
                                    (res) => setRender(!render),
                                    setLoad(false)
                                  );
                                }}
                                class="remove"
                              >
                                <i
                                  style={{ fontSize: "23px" }}
                                  class="bx bx-trash"
                                ></i>
                              </a>
                            </td>
                          </tr>
                        </tbody>
                      ))
                    ) : (
                      <center>
                        <img
                          src={wishlistimage}
                          className="mt-5 mb-5"
                          style={{ width: "25%" }}
                        />
                        <h3 className="mb-5 pb-3"> No Wishlist Found</h3>
                      </center>
                    )}
                  </table>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Wishlist;
