import React, { useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./CheckoutForm";
import { useLocation, Navigate } from "react-router-dom";
import "./payment.css";
import { axiosInstance } from "../Services";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

function Payment() {
  const { addressId } = useLocation().state;

  const [checkoutData, setCheckoutData] = useState(null);

  const getCheckoutData = async () => {
    try {
      const {
        data: { data },
      } = await axiosInstance(
        "post",
        process.env.REACT_APP_BASE_URL + "/user/orders/checkout",
        {
          shippingAddress: addressId,
        }
      );

      setCheckoutData(data);
    } catch (error) {
      return null;
    }
  };

  useEffect(() => {
    getCheckoutData();

    return () => {};
  }, []);

  const options = {
    mode: "payment",
    amount: parseInt(checkoutData?.price),
    currency: "inr",
    appearance: {
      variables: {
        borderRadius: "0px",
      },
    },
  };
  if (!addressId) return <Navigate to="/Checkout" />;

  if (!checkoutData)
    return (
      <div
        style={{
          height: "500px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    );

  return (
    <Elements stripe={stripePromise} options={options}>
      <CheckoutForm
        checkoutData={checkoutData}
        clientSecret={checkoutData?.paymentIntent}
      />
    </Elements>
  );
}

export default Payment;
