import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { authenticatelogin } from "../../Actions/Action";
import { useDispatch, useSelector } from "react-redux";
function Login() {
  const dispatch = useDispatch();
  const [count, setCount] = useState(true);
  const [passworderror, setPassworderror] = useState("");
  const [user, setUser] = useState({
    email: "",
    password: "",
  });
  const handlechange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const Navigate = useNavigate();
  const handleSubmit = (e) => {
    e.preventDefault();
    if (user.password.length > 3) {
      dispatch(authenticatelogin(user))
        .then((res) => {
          if (res.data.data === "Wrong Email/Password. Try Again!") {
            setPassworderror(res.data.data);
          } else {
            window.location.href = "/";
          }
        })
        .catch((err) => {
          setPassworderror(err.response.data.error.message);
        });
    } else {
      setPassworderror("password must be  greater than 3 letters");
    }
  };

  return (
    <div>
      <div class="page-title-area">
        <div class="container">
          <div
            class="page-title-content"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>Login</li>
            </ul>
          </div>
        </div>
      </div>
      <section class="login-area ptb-100">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-6">
              <div class="login-form">
                <div class="sign-in-title">
                  <h3>Login</h3>
                </div>
                <form onSubmit={handleSubmit}>
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="form-group">
                        <input
                          type="email"
                          class="form-control"
                          placeholder="Email"
                          name="email"
                          onChange={handlechange}
                          value={user.email}
                          required
                        />
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <div class="form-group">
                        <input
                          type="password"
                          class="form-control"
                          placeholder="Password"
                          name="password"
                          onChange={handlechange}
                          value={user.password}
                          required
                        />
                        <span style={{ color: "brown", marginLeft: "20px" }}>
                          {passworderror}
                        </span>
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <div class="form-check">
                        {/* <input type="checkbox" class="form-check-input" id="checkme" /> */}
                        {/* <label class="form-check-label" for="checkme">Keep Me Logged In</label> */}
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <p class="forgot-password">
                        <Link to="/ForgotPassword">Forgot Password?</Link>
                      </p>
                    </div>
                    <div class="col-lg-12">
                      <div class="send-btn">
                        <button type="submit" class="default-btn">
                          Login
                          <span></span>
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="new-customer-content">
                <h2>New Customer?</h2>
                <p>
                  Sign up for a free account at our store. Registration is quick
                  and easy. It allows you to be able to order from our shop. To
                  start shopping click register.
                </p>
                <Link to="/Register" class="default-btn">
                  Create an Account
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Login;
