import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { verifypasswords } from "../Actions/Action";
import Swal from "sweetalert2";

function ForgotPassword() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const [data, setData] = useState({
    email: id,
    code: "",
    password: "",
  });
  const [pass, setPass] = useState("");
  useEffect(() => {
    window.scrollTo(1000, 0);
  }, []);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (data.password != pass) {
      setError("Wrong Password");
    } else {
      dispatch(verifypasswords(data))
        .then((res) => {
          localStorage.clear();
          Swal.fire(res?.data?.data);
          navigate("/login");
        })
        .catch((err) => {
          var errMsg = err.response.data.error.message;
          if (errMsg === "Code Expired") {
            navigate("/ForgotPassword");
            var errMsg = "Code Expired, Try Again !";
            localStorage.setItem("Error", errMsg);
          } else {
            setError(err.response.data.error.message);
          }
        });
    }
  };
  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };
  return (
    <div>
      <div class="page-title-area">
        <div class="container">
          <div
            class="page-title-content"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <h2>Verify Password</h2>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/ForgotPassword">Reset Password</Link>
              </li>
              <li>Verify Password</li>
            </ul>
          </div>
        </div>
      </div>
      <section class="forgot-password-area ptb-100">
        <div class="container">
          <div class="forgot-password-form">
            <div class="forgot-password-in-title">
              <h3>Verify Your Password</h3>
            </div>
            <form onSubmit={handleSubmit}>
              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group">
                    <input
                      type="text"
                      onChange={handleChange}
                      name="code"
                      class="form-control"
                      placeholder="Verify Code"
                      required={true}
                    />
                    <input
                      type="password"
                      onChange={handleChange}
                      name="password"
                      class="form-control"
                      placeholder="Password"
                      required={true}
                    />
                    <input
                      type="password"
                      class="form-control"
                      onChange={(e) => setPass(e.target.value)}
                      name="verifypassword"
                      placeholder="Verify Password"
                      required={true}
                    />
                  </div>
                </div>
                <span style={{ color: "red" }} className="mb-1 pb-4">
                  {" "}
                  {error}{" "}
                </span>
                <div class="col-lg-12">
                  <div class="send-btn">
                    <button type="submit" class="default-btn">
                      Submit
                      <span></span>
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </div>
  );
}

export default ForgotPassword;
