import React, { useEffect } from "react";
import { Link } from "react-router-dom";
function ReturnPolicy() {
  useEffect(() => {
    window.scrollTo(1000, 0);
  }, []);
  return (
    <>
      <div class="page-title-area">
        <div class="container">
          <div
            class="page-title-content"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <h2>Return Policy</h2>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>Return Policy</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="container ptb-100 mb-3">
        <center>
          {" "}
          <h2 className="pb-4">
            <strong>Return & Refund Policy for heboo</strong>
          </h2>
        </center>
        <p>Welcome to heboo.in!</p>
        <p>Definitions and key terms</p>
        <h3>
          <strong>Company</strong>
        </h3>
        <p>
          When this policy mentions "Company," "we," "us," or "our," it refers
          to Heboo, 108, Pv Palayam Post, Perundurai, Erode,Tamilnadu - 638052
          that is responsible for your information under this Return & Refund
          Policy.
        </p>
        <p>
          <span style={{ fontSize: "20px", fontWeight: "600", color: "#000" }}>
            Customer :{" "}
          </span>
          Refers to the company, organisation or person that signs up to use the
          Service to manage the relationships with your consumers or service
          users.
        </p>

        <p>
          <span style={{ fontSize: "20px", fontWeight: "600", color: "#000" }}>
            Device :{" "}
          </span>
          Refers to any internet-connected device such as a phone, tablet,
          computer, or any other device that can be used to visit and use the
          services.
        </p>
        <p>
          <span style={{ fontSize: "20px", fontWeight: "600", color: "#000" }}>
            Service :{" "}
          </span>
          Refers to the service provided by Heboo as described in the relative
          terms (if available) and on this platform
        </p>
        <p>
          <span style={{ fontSize: "20px", fontWeight: "600", color: "#000" }}>
            Website :{" "}
          </span>
          Refers to Heboo's site, which can be accessed via this URL:
          https://heboo.in/.
        </p>
        <p>
          <span style={{ fontSize: "20px", fontWeight: "600", color: "#000" }}>
            You :{" "}
          </span>
          Refers to a person or entity that is registered with heboo to use the
          Services
        </p>
        <p>
          <span style={{ fontSize: "20px", fontWeight: "600", color: "#000" }}>
            Shipping :{" "}
          </span>
          Heboo is responsible for return shipping costs. Every returning
          shipping is paid by Heboo, even if the item didn't have free shipping
          in the first place.
        </p>
        <h3>
          <strong>Return & Refund Policy</strong>
        </h3>
        <p>
          Thanks for shopping at Heboo. We appreciate the fact that you like to
          buy the stuff we build. We also want to make sure you have a rewarding
          experience while you're exploring, evaluating, and purchasing our
          products.
        </p>
        <p>
          As with any shopping experience, there are terms and conditions that
          apply to transactions at Heboo. We'll be as brief as our attorneys
          will allow. The main thing to remember is that by placing an order or
          making a purchase at Heboo, you agree to the terms set forth below
          along with Heboo's Privacy Policy.
        </p>
        <p>
          If there's something wrong with the product you bought, or if you are
          not happy with it, you will not be able to issue a refund for your
        </p>
        <h3>
          <strong>Refunds</strong>
        </h3>
        <p>
          We at Heboo commit ourselves to serving our customers with the best
          products. Every single product that you choose is thoroughly
          inspected, checked for defects and packaged with utmost care. We do
          this to ensure that you fall in love with our products.
        </p>
        <p>
          Sadly, there are times when we may not have the product(s) that you
          choose in stock, or may face some issues with our inventory and
          quality check. In such cases, we may have to cancel your order. You
          will be intimated about it in advance so that you don't have to worry
          unnecessarily about your order. If you have purchased via Online
          payment (not Cash on Delivery), then you will be refunded once our
          team confirms your request.
        </p>
        <p>
          We carry out thorough quality check before processing the ordered
          item. We take utmost care while packing the product. At the sametime
          we ensure that the packing is good such that the items won't get
          damaged during transit. Please note that Heboo is not liablefor
          damages that are caused to the items during transit or transportation.
        </p>
        <h5>
          We follow certain policies to ensure transparency, efficiency and
          quality customer care:
        </h5>
        <p>
          We DO NOT allow returns on sold products - online or in retail
          outlets.
        </p>
        <p>
          We DO NOT accept returned goods, as we believe that customers should
          get the best quality products.
        </p>

        <p>
          Refunds are NOT given for any purchases made - be they online or in
          retail store.
        </p>

        <p>We DO NOT encourage exchanges of our products.</p>
        <p>
          We DO NOT engage in reselling used products and discourage the same,
          because we cannot ensure the best quality products for our customers.
        </p>

        {/* <ol>
          <li>
            We DO NOT allow returns on sold products - online or in retail
            outlets.
          </li>
          <li>
            We DO NOT accept returned goods, as we believe that customers should
            get the best quality products.
          </li>
          <li>
            Refunds are NOT given for any purchases made - be they online or in
            retail store.
          </li>
          <li>We DO NOT encourage exchanges of our products.</li>
          <li>
            We DO NOT engage in reselling used products and discourage the same,
            because we cannot ensure the best quality products for our
            customers.
          </li>
        </ol> */}

        <h3>
          <strong>For International Orders:</strong>
        </h3>
        <p>We DO NOT support Exchanges or Returns.</p>
        <p>
          If you cancel the order before we process it and dispatch for
          shipping, a refund can be processed. Orders generally take 1-2 days to
          process before dispatch.
        </p>
        <p>
          Orders already in shipping cannot be returned, canceled or refunded.
        </p>
        <p>
          If you face any issues, please contact our Support Team immediately.
        </p>
        <h3>
          <strong>Your Consent</strong>
        </h3>
        <p>
          By using our platform, registering an account, or making a purchase,
          you hereby consent to our Return & Refund Policy and agree to its
          terms.
        </p>
        <h3>
          <strong>Changes To Our Return & Refund Policy</strong>
        </h3>
        <p>
          Should we update, amend or make any changes to this document so that
          they accurately reflect our Service and policies. Unless otherwise
          required by law, those changes will be prominently posted here. Then,
          if you continue to use the Service, you will be bound by the updated
          Return & Refund Policy. If you do not want to agree to this or any
          updated Return & Refund Policy, you can delete your account
        </p>
        <h3>
          <strong>Contact Us</strong>
        </h3>
        <p>
          If, for any reason, You are not completely satisfied with any good or
          service that we provide, don't hesitate to contact us and we will
          discuss any of the issues you are going through with our product.
        </p>
        <p>Via Email: heboo.in@gmail.com</p>
        <p>Via Phone Number: +91 99654 88888</p>
      </div>{" "}
    </>
  );
}

export default ReturnPolicy;
