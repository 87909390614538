import React, { useEffect, useState } from "react";
import { myreviews } from "../Actions/Action";
import { useDispatch, useSelector } from "react-redux";
import { deletereviews } from "../Actions/Action";
import reviews from '../reviews found.svg'
import ReviewModal from "./ReviewModal";
function ListReviews() {
  const [show, setShow] = useState(true);
  const [render, setRender] = useState(false)
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(myreviews());
  }, [render]);
  const myreview = useSelector(({ myreviews }) => myreviews.payload);
  const font = {
    fontWeight: "bold",
    textTransform: "Capitalize",
    textAlign: "center",
  };
  const weight = {
    textTransform: "Capitalize",
    color: "black",
    textAlign: "center",
  };
  const imagee = {
    width: "100px",
  };
  const handlereview = (G) => {
    dispatch(deletereviews({ action: "delete", id: G.id, rating: G.rating, review: G.review }));
    (setRender(!render));
  };

  return (
    <div className="container ">
      <div className="cart-table table-responsive">
        {Array.isArray(myreview) ? (
          <h2 className="d-flex justify-content-center mb-5">Review List</h2>
        ) : (
          false
        )}
        <table class="table table-bordered">
          {Array.isArray(myreview) ?
            <thead>
              <tr>
                <th scope="col" style={font}>
                  S.No
                </th>
                <th scope="col" style={font}>
                  Product Name
                </th>
                <th scope="col" style={font}>
                  Product Image
                </th>
                <th scope="col" style={font}>
                  Actual Price
                </th>
                <th scope="col" style={font}>
                  Review
                </th>
                <th scope="col" style={font}>
                  Rating
                </th>
                <th scope="col" style={font}>
                  Action
                </th>
              </tr>
            </thead>
            :
            <h3 className="pt-5 pb-5">  <center><img src={reviews} className='mt-5 mb-5 pb-5 pt-5' style={{width:"25%"}} />
            <h3>   No Reviews Found</h3>  </center></h3>
          }
          <tbody>
            {Array.isArray(myreview) && myreview.length !== 0
              ? myreview?.map((G, i) => (
                <tr>
                  <td style={weight}>{i + 1}</td>
                  <td style={weight}>{G.productName}</td>
                  <td style={{ textAlign: "center" }}>
                    <img style={imagee} src={G.variantImage} />
                  </td>
                  <td style={weight}> ₹{G.actualPrice}/-</td>
                  <td style={weight}> {G.review}</td>
                  <td style={weight}> {G.rating}</td>
                  <td style={{ textAlign: "center" }}>
                    <span
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                    >
                      <a
                        style={{ cursor: "pointer", paddingLeft: "10px" }}
                        onClick={() => {
                          if (show !== i)
                            setShow(i);
                        }}
                      >
                        <i
                          style={{
                            fontSize: "23px",
                            backgroundColor: "#0000001c",
                            borderRadius: "0",
                            padding: "10px",
                          }}
                          class="bx bx-edit-alt"
                        ></i>
                      </a>
                    </span>
                    <span onClick={() => handlereview(G)}>
                      <a style={{ cursor: "pointer", paddingLeft: "10px" }}>
                        <i
                          style={{
                            fontSize: "23px",
                            backgroundColor: "#0000001c",
                            borderRadius: "0",
                            padding: "10px",
                          }}
                          class="bx bx-trash"
                        ></i>
                      </a>
                    </span>
                  </td>
                </tr>
              ))
              : false}
          </tbody>
        </table>
      </div>
      {/* <!-- Modal --> */}
      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                Review
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              {show || show == 0 ? <ReviewModal myreview={myreview?.[show]} /> : false}
            </div>
            <div class="modal-footer">
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ListReviews;
