import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
function Contact() {
    useEffect(() => {
        window.scrollTo(1000, 0);
    }, [])
    return (
        <div>
            <div class="page-title-area">
                <div class="container">
                    <div class="page-title-content" style={{ display: "flex", justifyContent: "space-between" }}>
                        <h2>Contact </h2>
                        <ul>
                            <li><Link to="/">Home</Link></li>
                            <li>Contact </li>
                        </ul>
                    </div>
                </div>
            </div>
            <section class="contact-area ptb-50">
                <div class="container">
                    <div class="section-title">
                        {/* <p>Contact Us</p> */}
                        <h2 className='mt-5'>Drop <span style={{ textTransform: "lowercase" }}> us a</span> Message </h2>
                    </div>
                    <div class="contact-form">
                        <form id="contactForm">
                            <div class="row  mb-5">
                                <div class="col-lg-6 col-md-12" style={{ height: "85px" }}>
                                    <div class="form-group">
                                        <input type="text" name="name" id="name" class="form-control" required data-error="Please enter your name" placeholder="Name" />
                                        <div class="help-block with-errors"></div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-12" >
                                    <div class="form-group">
                                        <input type="email" name="email" id="email" class="form-control" required data-error="Please enter your email" placeholder="Email" />
                                        <div class="help-block with-errors"></div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-12" style={{ height: "85px" }}>
                                    <div class="form-group">
                                        <input type="text" name="phone_number" pattern="[789][0-9]{9}"   id="phone_number" required data-error="Please enter your number" class="form-control" placeholder="Phone" />
                                        <div class="help-block with-errors"></div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-12">
                                    <div class="form-group">
                                        <input type="text" name="msg_subject" id="msg_subject" class="form-control" required data-error="Please enter your subject" placeholder="Subject" />
                                        <div class="help-block with-errors"></div>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12" style={{ height: "168px" }}>
                                    <div class="form-group">
                                        <textarea name="message" class="form-control" id="message" cols="30" rows="4" required data-error="Write your message" placeholder="Your Message"></textarea>
                                        <div class="help-block with-errors"></div>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12 pb-3 pt-4">
                                    <button type="submit" class="default-btn">
                                        Send Message
                                        <span></span>
                                    </button>
                                    <div id="msgSubmit" class="h3 text-center hidden"></div>
                                    <div class="clearfix"></div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </section>


            {/* <div class="subscribe-area ptb-100">
<div class="container">
<div class="row align-items-center">
<div class="col-lg-6 col-md-6">
<div class="subscribe-content">
<h2>Don't Miss Our Update</h2>
<p>There are many variations of passages of Lorem Ipsum available</p>
</div>
</div>
<div class="col-lg-6 col-md-6">
<form class="newsletter-form">
<input type="email" class="input-newsletter" placeholder="Your Email" name="EMAIL" required autocomplete="off" />
<button type="submit">
Subscribe Now
</button>
<div id="validator-newsletter" class="form-result"></div>
</form>
</div>
</div>
</div>
</div> */}

            {/* 
<div class="map-area ptb-100">
<div class="container">
<div class="map-inner">
<iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d1605.811957341231!2d25.45976406005396!3d36.3940974010114!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1550912388321" allowfullscreen=""></iframe>
</div>
</div>
</div> */}



        </div>
    )
}

export default Contact
