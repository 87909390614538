import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createStore, compose, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import Reducer from "./Reducer/index";
import { setHeaderToken } from "./Services";
const store = createStore(Reducer, compose(applyMiddleware(thunk)));
const token = localStorage.getItem("AuthTok");
if (token) {
  setHeaderToken(token);
}

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);

reportWebVitals();
