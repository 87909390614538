import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { addAddress, getAddress } from "../Actions/Action";
import Swal from "sweetalert2";

import "./AddressForm.css";
import GetAddress from "./GetAddress";
import { useSearchParams, useNavigate } from "react-router-dom";
function AddressForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const isFromCheckout = Boolean(searchParams.get("isFromCheckout"));

  const [address, setAddress] = useState({
    state: "",
    city: "",
    street: "",
    landmark: "",
    zipcode: "",
    primary: "",
    district: "",
    addressType: "",
  });
  const handleChange = (e) => {
    setAddress({ ...address, [e.target.name]: e.target.value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(addAddress(address)).then((res) => {
      Swal.fire(res?.data?.data);
      setAddress({
        state: "",
        city: "",
        street: "",
        landmark: "",
        zipcode: "",
        primary: "",
        district: "",
        addressType: "",
      });
      if (isFromCheckout) {
        navigate("/checkout");
      }
      dispatch(getAddress());
    });
  };
  useEffect(() => {
    window.scrollTo(1000, 0);
  }, []);
  return (
    <div>
      <section class="register-area">
        <div class="container col-lg-6 col-md-12  col-sm-12 d-flex justify-content-start">
          <div class="register-form">
            <div class="register-in-title">
              <h3 style={{ textAlign: "center" }}>Add Address</h3>
            </div>
            <form onSubmit={handleSubmit}>
              <div class="row">
                <div class="col-lg-6">
                  <div class="form-group">
                    <input
                      type="text"
                      name="street"
                      value={address.street}
                      onChange={handleChange}
                      class="form-control"
                      placeholder="Street"
                      required
                    />
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group">
                    <input
                      type="text"
                      name="city"
                      value={address.city}
                      onChange={handleChange}
                      class="form-control"
                      placeholder="City"
                      required
                    />
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group">
                    <input
                      type="text"
                      name="district"
                      value={address.district}
                      onChange={handleChange}
                      class="form-control"
                      placeholder="District"
                      required
                    />
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group">
                    <input
                      type="text"
                      name="landmark"
                      value={address.landmark}
                      onChange={handleChange}
                      class="form-control"
                      placeholder="Landmark"
                      required
                    />
                  </div>
                </div>

                {/* <label className="mb-3" style={{fontWeight:"bold"}}>Gender</label> */}
                {/* <div class="col-lg-6">
                  <div class="form-group">
                    <input
                      type="text"
                      name="primary"
                      value={address.primary}
                      onChange={handleChange}
                      class="form-control"
                      placeholder="Primary"
                      required
                    />
                  </div>
                </div> */}
                <div class="col-lg-6">
                  <div class="form-group">
                    <input
                      type="text"
                      name="state"
                      value={address.state}
                      onChange={handleChange}
                      class="form-control"
                      placeholder="State"
                      required
                    />
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group">
                    <input
                      type="text"
                      name="zipcode"
                      value={address.zipcode}
                      onChange={handleChange}
                      class="form-control"
                      placeholder="Pincode"
                      required
                    />
                  </div>
                </div>
                <div class="form-group">
                  <select
                    class="form-select"
                    value={address.addressType}
                    onChange={handleChange}
                    name="addressType"
                    aria-label="Default select example"
                    style={{
                      maxWidth: "529px",
                      marginLeft: "0px",
                      height: "49px",
                    }}
                  >
                    <option selected> Choose Address </option>
                    <option value="Home">Home </option>
                    <option value="Office">Office</option>
                  </select>
                </div>
                <div class="form-group">
                  <select
                    class="form-select"
                    value={address.primary}
                    onChange={handleChange}
                    name="primary"
                    aria-label="Default select example"
                    style={{
                      maxWidth: "529px",
                      marginLeft: "0px",
                      height: "49px",
                    }}
                  >
                    <option selected> Is this Your Primary Address?</option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </select>{" "}
                </div>
                <div class="col-lg-12">
                  <div
                    class="send-btn"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <button type="submit" class="default-btn">
                      Add Address
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
      <GetAddress />
    </div>
  );
}

export default AddressForm;
