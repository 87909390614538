import React, { useEffect, useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { useDispatch, useSelector } from "react-redux";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Link } from "react-router-dom";
import { foryous, getbanner, getcategories, hotdeals } from "../Actions/Action";
const Token = localStorage.getItem("AuthTok");

function Home() {
  const [load, setLoad] = useState(true);
  const [render, setRender] = useState(true);
  const category = useSelector(({ getcategories }) => getcategories.payload);
  const banner = useSelector(({ getbanner }) => getbanner.payload);
  const deal = useSelector(({ hotdeals }) => hotdeals.payload);
  const you = useSelector(({ foryous }) => foryous.payload);
  const dispatch = useDispatch();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    Promise.allSettled([
      dispatch(getbanner()),
      dispatch(getcategories()),
      dispatch(hotdeals()),
      dispatch(foryous()),
    ])
      .then((res) => setLoad(false))
      .catch(() => {
        console.log("Waiting For Network");
      });
  }, []);
  useEffect(() => {
    localStorage.removeItem("Error");
  }, []);
  useEffect(() => {
    if (Array.isArray(category) && category.length > 0) {
      setTimeout(() => {
        window.$(".instagram-slides").owlCarousel({
          loop: true,
          nav: false,
          dots: false,
          autoplayHoverPause: true,
          autoplay: true,
          margin: 10,
          navText: [
            "<i class='flaticon-left'></i>",
            "<i class='flaticon-right-arrow'></i>",
          ],
          responsive: {
            0: {
              items: 2,
            },
            576: {
              items: 2,
            },
            768: {
              items: 3,
            },
            1200: {
              items: 6,
            },
          },
        });
      }, 1000);
    }
  }, [category]);
  return load ? (
    <div
      style={{
        height: "500px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  ) : (
    <div>
      <div className="container-xl" style={{ marginTop: "35px" }}>
        <Carousel>
          {Array.isArray(banner) && banner.length !== 0 ? (
            banner?.map((B, index) => (
              <Carousel.Item
                style={{
                  background: "#fcfcfc",
                  overflow: "hidden",
                }}
              >
                <img
                  style={{
                    borderRadius: "3px",
                    display: "block",
                    maxHeight: "80vh",
                    aspectRatio: "1500/653",
                    objectFit: "contain",
                  }}
                  src={process.env.REACT_APP_BASE_URL + B.bannerImage}
                  alt={"heboo banner" + index + 1}
                />
              </Carousel.Item>
            ))
          ) : (
            <div class="spinner spinner-border" role="status"></div>
          )}
        </Carousel>
      </div>

      {/* Category  carousl start*/}
      <section class="instagram-area mt-4 pt-5 pb-5">
        <div class="container ">
          <div
            className="pb-5"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <h2>Category</h2>
            <h6 style={{ textTransform: "none" }}>
              <OverlayTrigger
                overlay={<Tooltip id="tooltip-disabled">All</Tooltip>}
              >
                <span className="d-inline-block">
                  <Link to="/Categories">See all</Link>
                </span>
              </OverlayTrigger>
            </h6>
          </div>

          <div class="instagram-slides owl-carousel owl-theme">
            {Array.isArray(category) && category.length !== 0
              ? category?.map((C) => (
                  <div>
                    <Link to={"/CategoryShop/" + C.id}>
                      <img
                        src={process.env.REACT_APP_BASE_URL + C.categoryImage}
                        alt={C.categoryName}
                      />
                    </Link>
                    <h5 style={{ textAlign: "center", marginTop: "10px" }}>
                      <Link to={"/CategoryShop/" + C.id} style={{}}>
                        {C.categoryName}
                      </Link>
                    </h5>
                  </div>
                ))
              : "No Category Found"}
          </div>
        </div>
      </section>

      {/* featured  banner */}
      {/* <div className="container  mt-4 pt-5 pb-5 " id="bannerimagee">
        <img
          src="https://ik.imagekit.io/lokki/Banners/banner01_igv2Nsd0ZrL.png?updatedAt=1642050761681"
          alt="hebbo leather bags"
          style={{
            width: "100%",
            height: undefined,
            aspectRatio: 16 / 9,
          }}
        />
      </div> */}

      {/*  Recommended For You */}
      {Token ? (
        <section class="collections-area mt-4 pt-5 pb-5">
          <div class="container">
            <div class="section-title">
              <h2
                style={{ textAlign: "start", fontSize: "32px" }}
                className="pt-3 pb-3"
              >
                Recommended For You
              </h2>
            </div>
            <div class="row">
              {Array.isArray(you)
                ? you
                    ?.filter((Y) => Y.discountPrice >= 1)
                    .map((Y) => (
                      <div class="col-xl-3 col-lg-4 col-6">
                        <div class="collections-item">
                          <div class="image">
                            <Link to={"/productDetails/" + Y.id}>
                              <img
                                src={
                                  process.env.REACT_APP_BASE_URL +
                                  Y.productImage
                                }
                                alt={Y.productName}
                                style={{
                                  width: "100%",
                                  aspectRatio: "1 / 1",
                                }}
                              />
                            </Link>
                            <div class="shop-btn">
                              <Link
                                to={"/productDetails/" + Y.id}
                                class="default-btn"
                              >
                                Shop Now
                              </Link>
                            </div>
                          </div>
                          <div class="content">
                            <h3>
                              <Link to={"/ProductDetails/" + Y.id}>
                                {Y.productName}
                              </Link>
                            </h3>
                            <span>₹{Y.discountPrice}</span>
                          </div>
                        </div>
                      </div>
                    ))
                : false}
            </div>
          </div>
        </section>
      ) : null}

      {/* Hot Deals */}
      <section class="collections-area mt-4 pt-5 pb-5">
        <div class="container">
          <div class="section-title">
            {Array.isArray(deal) && deal.length !== 0 ? (
              <h2
                style={{ textAlign: "start", fontSize: "32px" }}
                className="pb-3"
              >
                Hot Deals
              </h2>
            ) : (
              false
            )}
          </div>
          <div class="row">
            {Array.isArray(deal) && deal.length !== 0
              ? deal
                  .filter((Y) => Y.discountPrice >= 1)
                  .map((Y) => (
                    <div class="col-xl-3 col-lg-4 col-6">
                      <div class="collections-item">
                        <div class="image">
                          <Link to={"/productDetails/" + Y.id}>
                            <img
                              src={
                                process.env.REACT_APP_BASE_URL + Y.productImage
                              }
                              style={{
                                width: "100%",
                                aspectRatio: "1 / 1",
                              }}
                              alt={Y.productName}
                            />
                          </Link>
                          <div class="shop-btn">
                            <Link
                              to={"/productDetails/" + Y.id}
                              class="default-btn"
                            >
                              Shop Now
                            </Link>
                          </div>
                        </div>
                        <div class="content">
                          <h3>
                            <Link to={"/ProductDetails/" + Y.id}>
                              {Y.productName}
                            </Link>
                          </h3>
                          <span>₹{Y.discountPrice}</span>
                        </div>
                      </div>
                    </div>
                  ))
              : false}
          </div>
        </div>
      </section>
    </div>
  );
}
export default Home;
