import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getcategories } from "../Actions/Action";
import img from "./No.jpg";
function Categories() {
  const [load, setLoad] = useState(true);
  const dispatch = useDispatch();
  const category = useSelector(({ getcategories }) => getcategories.payload);
  useEffect(() => {
    dispatch(getcategories()).then((res) => setLoad(false));
  }, []);

  let navigate = useNavigate();
  function catshop(id) {
    navigate("/CategoryShop/" + id);
  }
  useEffect(() => {
    window.scrollTo(1000, 0);
  }, []);
  return load ? (
    <div
      style={{
        height: "400px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  ) : (
    <div>
      <div class="page-title-area">
        <div class="container">
          <div
            class="page-title-content"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <h2>Categories</h2>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>Categories</li>
            </ul>
          </div>
        </div>
      </div>
      <section class="categories-area pt-100 pb-70">
        <div class="container">
          <div class="section-title">
            {/* <p>Collections</p> */}
            <h2>Popular Categories</h2>
          </div>
          <div class="row">
            {Array.isArray(category) && category.length !== 0 ? (
              category?.map((x) => (
                <div class="col-lg-3 col-md-6">
                  <div class="categories-item">
                    <div class="image">
                      <a>
                        <img
                          style={{ maxWidth: "70%", cursor: "pointer" }}
                          onClick={() => catshop(x.id)}
                          src={process.env.REACT_APP_BASE_URL + x.categoryImage}
                          alt={x.categoryName}
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src = img;
                          }}
                        />
                      </a>
                    </div>
                    <h3
                      className="mt-3"
                      style={{ cursor: "pointer" }}
                      onClick={() => catshop(x.id)}
                    >
                      {x.categoryName}
                    </h3>
                  </div>
                </div>
              ))
            ) : (
              <h3 className="container">"No Category Found!"</h3>
            )}
          </div>
        </div>
      </section>
    </div>
  );
}

export default Categories;
