import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { registers } from "../../Actions/Action";

import "moment-timezone";
import Swal from "sweetalert2";

const inputMaxField = {
  maxWidth: "600px",
  margin: "0 auto",
};

function Register() {
  const [user, setUser] = useState({
    userName: "",
    email: "",
    password: "",
    phone: "",
    dob: "",
    gender: "",
    fcmToken: "wertyuio45frtgyh",
  });

  const [err, setErr] = useState("");
  const [errL, setErrL] = useState("");
  const [value, setValue] = useState();
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const handleSubmit = (e) => {
    e.preventDefault();
    if (user.phone.length !== 10) {
      setErr("Mobile Number Should be 10");
    } else if (user.password.length < 5) {
      setErrL("Minimum Password Length Required");
    } else {
      dispatch(registers(user)).then((res) => {
        Swal.fire(res?.data?.data);

        navigate("/login");
      });
      //  window.location.href="/"
    }
  };
  useEffect(() => {}, []);
  const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };
  useEffect(() => {
    window.scrollTo(1000, 0);
  }, []);
  return (
    <div>
      <div class="page-title-area">
        <div class="container">
          <div
            class="page-title-content"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <h2>Register</h2>
            <ul>
              <li>
                <a href="/">Home</a>
              </li>
              <li>Register</li>
            </ul>
          </div>
        </div>
      </div>
      <section class="register-area ptb-100">
        <div class="container">
          <div class="register-form">
            <div class="register-in-title">
              <h3 style={{ display: "flex", justifyContent: "center" }}>
                Register
              </h3>
            </div>
            <form onSubmit={handleSubmit}>
              <div style={inputMaxField}>
                <div class="form-group">
                  <input
                    type="text"
                    name="userName"
                    value={user.userName}
                    onChange={handleChange}
                    class="form-control"
                    placeholder="Username"
                    required
                  />
                </div>
              </div>
              <div style={inputMaxField}>
                <div class="form-group">
                  <input
                    type="email"
                    name="email"
                    value={user.email}
                    onChange={handleChange}
                    class="form-control"
                    placeholder="Email"
                    required
                  />
                </div>
              </div>
              <div style={inputMaxField}>
                <div class="form-group">
                  <input
                    type="password"
                    name="password"
                    value={user.password}
                    onChange={handleChange}
                    class="form-control"
                    placeholder="Password"
                    required
                  />
                  <span style={{ color: "red" }}>{errL} </span>
                </div>
              </div>
              <div style={inputMaxField}>
                <div class="form-group">
                  <input
                    type="text"
                    placeholder="Enter phone number"
                    name="phone"
                    value={user.phone}
                    class="form-control"
                    onChange={handleChange}
                  />
                  <span style={{ color: "red" }}>{err} </span>
                </div>
              </div>
              <div style={inputMaxField}>
                <div class="form-group">
                  <input
                    type="date"
                    name="dob"
                    value={user.dob}
                    onChange={handleChange}
                    class="form-control"
                    placeholder="Dob"
                    required
                  />
                </div>
              </div>

              <div style={inputMaxField}>
                <div class="form-group">
                  <select
                    class="form-select "
                    value={user.gender}
                    onChange={handleChange}
                    name="gender"
                    aria-label="Default select example"
                    style={{
                      padding: "8px 12px 10px 20px",
                      marginBottom: "6px",
                    }}
                  >
                    <option className="form-select-item" selected>
                      {" "}
                      Choose Gender
                    </option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="others">Transgender</option>
                  </select>
                </div>
              </div>

              <div style={inputMaxField}>
                <Link
                  to="/login"
                  style={{ fontWeight: "bold", marginLeft: "10px" }}
                >
                  Already a User? Login
                </Link>
              </div>
              <div class="col-lg-12">
                <div
                  class="send-btn"
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <button type="submit" class="default-btn">
                    Register Now
                    <span></span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Register;
