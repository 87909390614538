import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { faqs } from "../../Actions/Action";

function Faq(props) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(faqs());
  }, []);

  const faqquest = useSelector(({ faqs }) => faqs.payload);
  return (
    <div>
      <div class="page-title-area">
        <div class="container">
          <div
            class="page-title-content"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <h2>Faq</h2>
            <ul>
              <li>
                <a href="/">Home</a>
              </li>
              <li>Faq</li>
            </ul>
          </div>
        </div>
      </div>

      <section class="mt-5">
        <div class="section-title">
          <p>Faq</p>
          <h2>Frequently ask question</h2>
        </div>
        <div class="container">
          <div class="row">
            {faqquest?.map((F) => (
              <div class="col-md-6 offset-md-3">
                <div class="accordion accordion-flush p-5" id="faqlist">
                  <div class="accordion-item">
                    <h2 class="accordion-header">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faq-content-2"
                      >
                        {F.title}
                      </button>
                    </h2>
                    <div
                      id="faq-content-2"
                      class="accordion-collapse collapse"
                      data-bs-parent="#faqlist"
                    >
                      <div class="accordion-body">{F.answer}</div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
}

export default Faq;
