import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getproducts, addwishlists, searchs } from "../Actions/Action";
import { authenticatelogin } from "../Actions/Action";
import { FaHeart, FaRegHeart } from "react-icons/fa";
import products from "../noproductsfound.svg";

function SearchProducts() {
  const dispatch = useDispatch();

  const [user, setUser] = useState({
    email: "",
    password: "",
  });
  const [loader, setLoader] = useState(true);
  const [passworderror, setPassworderror] = useState("");
  const product = useSelector(({ searchs }) => searchs.payload);
  const [load, setLoad] = useState(true);
  const Token = localStorage.getItem("AuthTok");
  const { id } = useParams();
  useEffect(() => {
    {
      setLoader(true);
      dispatch(searchs({ query: id })).then((res) => setLoader(false));
    }
  }, [id]);
  useEffect(() => {
    window.scrollTo(1000, 0);
  }, []);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (user.password.length > 3) {
      dispatch(authenticatelogin(user)).then((res) => {
        if (res.data.data === "Wrong Email/Password. Try Again!") {
          setPassworderror(res.data.data);
        } else {
          window.location.href = "/Shop";
        }
      });
    } else {
      setPassworderror("password must be  greater than 3 letters");
    }
  };
  const handlechange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };
  return loader ? (
    <div
      style={{
        height: "400px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  ) : (
    <>
      <div>
        <div class="page-title-area">
          <div class="container">
            <div
              class="page-title-content"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <h2>Search Products </h2>
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>

                <li>Search Products</li>
              </ul>
            </div>
          </div>
        </div>
        <section class="shop-area pt-50 pb-100">
          <div class="container">
            <div class="woocommerce-topbar">
              <div class="row align-items-center">
                <div class="col-lg-8 col-md-7 col-sm-6"></div>
              </div>
            </div>
            <div class="row">
              {Array.isArray(product) && product.length != 0 ? (
                product
                  ?.filter((Y) => Y.discountPrice >= 1)
                  .map((x) => (
                    <div class="col-lg-4 col-md-6">
                      <div class="products-item">
                        <div class="image">
                          <Link to={"/ProductDetails/" + x.id}></Link>
                          <ul class="social" style={{ marginTop: "-60px" }}>
                            {Token ? (
                              <li>
                                <a
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    fontSize: "20px",
                                  }}
                                  onClick={() => {
                                    setLoader(true);
                                    dispatch(
                                      addwishlists({ productId: x.id })
                                    ).then((res) => {
                                      dispatch(getproducts()).then((res) =>
                                        setLoader(false)
                                      );
                                    });
                                  }}
                                >
                                  {loader ? (
                                    <></>
                                  ) : x.favourites === 0 ? (
                                    <span>
                                      <FaRegHeart style={{ color: "black" }} />
                                    </span>
                                  ) : (
                                    <span>
                                      <FaHeart style={{ color: "black" }} />
                                    </span>
                                  )}
                                </a>
                              </li>
                            ) : (
                              <li>
                                <a
                                  data-bs-toggle="modal"
                                  data-bs-target="#exampleModal"
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    fontSize: "20px",
                                  }}
                                >
                                  {loader ? (
                                    <></>
                                  ) : x.favourites === 0 ? (
                                    <span>
                                      <FaRegHeart style={{ color: "black" }} />
                                    </span>
                                  ) : (
                                    <span>
                                      <FaHeart style={{ color: "black" }} />
                                    </span>
                                  )}
                                </a>
                              </li>
                            )}
                          </ul>
                          <div
                            class="modal fade"
                            id="exampleModal"
                            tabindex="-1"
                            aria-labelledby="exampleModalLabel"
                            aria-hidden="true"
                          >
                            <div class="modal-dialog modal-dialog-centered">
                              <div class="modal-content">
                                <div class="modal-header">
                                  <h5
                                    class="modal-title"
                                    id="exampleModalLabel"
                                  >
                                    Login
                                  </h5>
                                  <button
                                    type="button"
                                    class="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                  ></button>
                                </div>
                                <div class="modal-body">
                                  <form onSubmit={handleSubmit}>
                                    <div class="row">
                                      <div class="col-lg-12 mt-3 mb-3">
                                        <div class="form-group">
                                          <input
                                            type="email"
                                            class="form-control"
                                            placeholder="Email"
                                            name="email"
                                            style={{ borderRadius: "0px" }}
                                            onChange={handlechange}
                                            value={user.email}
                                            required
                                          />
                                        </div>
                                      </div>
                                      <div class="col-lg-12 mt-3">
                                        <div class="form-group">
                                          <input
                                            type="password"
                                            class="form-control"
                                            placeholder="Password"
                                            name="password"
                                            style={{ borderRadius: "0px" }}
                                            onChange={handlechange}
                                            value={user.password}
                                            required
                                          />
                                          <span
                                            style={{
                                              color: "brown",
                                              marginLeft: "20px",
                                            }}
                                          >
                                            {passworderror}
                                          </span>
                                        </div>
                                      </div>
                                      <div class="col-lg-12 mb-3">
                                        <p class="forgot-password">
                                          <a href="/Register">
                                            Don't you have an account?{" "}
                                            <b>Register</b>
                                          </a>
                                        </p>
                                      </div>
                                      <div class="col-lg-12">
                                        <div class="send-btn">
                                          <button
                                            type="submit"
                                            class="default-btn"
                                          >
                                            Login
                                            <span></span>
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* <div class="shop-btn">
                            <Link
                              to={"/ProductDetails/" + x.id}
                              style={{ cursor: "pointer" }}
                              class="default-btn">
                              View Product
                            </Link>
                          </div> */}
                        </div>
                        <div class="content">
                          <h3>
                            <img
                              src={
                                process.env.REACT_APP_BASE_URL + x.productImage
                              }
                              alt={x.productName}
                              style={{ height: "436px", width: "436px" }}
                            />
                            <Link to={"/ProductDetails/" + x.id}>
                              {x.productName}
                            </Link>
                          </h3>
                          <span>₹{x.discountPrice}</span>
                        </div>
                      </div>
                    </div>
                  ))
              ) : (
                <center className="pb-5 mb-5">
                  <img
                    src={products}
                    className="mt-5 mb-5"
                    style={{ width: "25%" }}
                  />
                  <h3 className="container">No Product Found</h3>
                </center>
              )}
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default SearchProducts;
