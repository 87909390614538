import React from "react";
import { Link, useLocation } from "react-router-dom";
import strictStyle from "./A_strictStyle.module.css";
import { Logo } from "./components/Logo.jsx";

import styled from "styled-components";

const WhatsappIcon = styled.i`
  &::before {
    font-size: 20px;
    vertical-align: bottom;
  }
`;

function Footer() {
  const location = useLocation();
  const d = new Date();
  let year = d.getFullYear();
  return (
    <>
      <section className="footer-area pt-100">
        <div className="container mt-5">
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="single-footer-widget">
                <div className="" style={{ marginBottom: "40px" }}>
                  <Link to="/">
                    <Logo
                      src="/assets/img/heboo-logo-light.svg"
                      alt="heboo-logo-light"
                    />
                  </Link>
                </div>
                <ul className="footer-contact-info">
                  <li>
                    <i className="flaticon-phone-call"></i>
                    <span>Phone</span>
                    <a href="tel:+91 99654 88888">+91 99654 88888</a>
                  </li>
                  <li>
                    <i className="flaticon-email-1"></i>
                    <span>Email</span>
                    <p>
                      <a href="mailto:heboo.in@gmail.com">heboo.in@gmail.com</a>
                    </p>
                  </li>
                  <li>
                    <i className="flaticon-pin"></i>
                    <span>Address</span>
                    <a>
                      108, Pv Palayam Post, Perundurai, Erode,
                      <br /> Tamilnadu - 638052
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="single-footer-widget pl-5">
                <h3>Company</h3>
                <ul className="quick-links">
                  <li>
                    <Link to="/shop">Shop</Link>
                  </li>
                  {/* <li>
                    <Link to="/about">About </Link>
                  </li> */}
                  <li>
                    <Link to="/TermsAndCondition">Terms and Conditions</Link>
                  </li>
                  <li>
                    <Link to="/ReturnPolicy">Return & Refund Policy</Link>
                  </li>
                  <li>
                    <Link to="/Privacypolicy">Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to="/Order">Orders</Link>
                  </li>

                  <li>
                    <Link to="/contact">Contact</Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="single-footer-widget">
                <h3>Links </h3>
                <ul class="footer-social">
                  <li>
                    <a
                      href="https://www.facebook.com/profile.php?id=100089733709954&mibextid=2JQ9oc"
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      <i className="flaticon-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://instagram.com/heboo.in?igshid=MTNiYzNiMzkwZA=="
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      <i className="flaticon-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://wa.me/919965488888"
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      <WhatsappIcon className="bx bxl-whatsapp" />
                    </a>
                  </li>
                </ul>
              </div>{" "}
              <div className="single-footer-widget">
                <p style={{ color: "white" }}>Product By <span style={{fontWeight:"600"}}>Thambiratti</span> </p>
              </div>
            </div>
          </div>
          <div className="footer-bottom-area">
            <div
              className="row align-items-center"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <div
                className="col-lg-4 col-md-4  col-sm-6"
                style={{ color: "white" }}
              >
                <span>
                  Copyright © {year}{" "}
                  <Link to="/" style={{ color: "white" }}>
                    Heboo
                  </Link>
                  .
                </span>
              </div>
              <div
                className="col-lg-4 col-md-4  col-sm-6"
                style={{
                  color: "white",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {location.pathname === "/About" ||
                location.pathname === "/Contact" ? (
                  <span>
                    Designed by{" "}
                    <a
                      href="https://nutz.in"
                      target="-blank"
                      style={{ color: "white" }}
                    >
                      Nutz
                    </a>
                  </span>
                ) : (
                  false
                )}
              </div>
              <div
                className="col-lg-4 col-md-4  col-sm-6 flex-end"
                style={{
                  color: "white",
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <span>All Rights Reserved</span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="go-top">
        <i className="bx bx-chevron-up"></i>
      </div>
    </>
  );
}

export default Footer;
