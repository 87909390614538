import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import Home from "./views/Home";
// import About from "./views/About";
import Header from "./views/Header";
import Footer from "./views/Footer";
import Shop from "./views/Shop";
import SearchProducts from "./views/SearchProducts";
import ProductDetails from "./views/ProductDetails";
import Cart from "./views/Cart";
import Login from "./views/auth/Login";
import Register from "./views/auth/Register";
import Checkout from "./views/Checkout";
import ForgotPassword from "./views/ForgotPassword";
import Categories from "./views/Categories";
import CategoryShop from "./views/CategoryShop";
import Contact from "./views/Contact";
import Wishlist from "./views/Wishlist";
import Order from "./views/card/Order";
import Getorderdetails from "./views/Getorderdetails";
import Faq from "./views/Faq/Faq";
import Profile from "./views/Profile";
import UpdateProfile from "./views/UpdateProfile";
import AddressForm from "./views/AddressForm";
import UpdateAddress from "./views/UpdateAddress";
import Search from "./views/Search/Search";
import GetAddress from "./views/GetAddress";
import TermsAndCondition from "./views/TermsAndCondition";
import Privacypolicy from "./views/Privacypolicy";
import Payment from "./views/Payment";
import VerifyPassword from "./views/VerifyPassword";
import ListReviews from "./views/ListReviews";
import OrderPlaced from "./views/OrderPlaced";
import Paymentverified from "./views/Paymentverified";
import Filter from "./views/fliter";
import ReturnPolicy from "./views/ReturnPolicy";


function App() {
  return (
    <>
      <BrowserRouter>
        <Header />
        <Search />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Profile" element={<Profile />} />
          <Route path="/UpdateProfile/:id" element={<UpdateProfile />} />
          <Route path="/orderplaced" element={<Paymentverified />} />
          {/* <Route path="/About" element={<About />} /> */}
          <Route path="/Filter" element={<Filter />} />
          <Route path="/Shop" element={<Shop />} />
          <Route path="/SearchProducts/:id" element={<SearchProducts />} />
          <Route path="/Categories" element={<Categories />} />
          <Route path="/CategoryShop/:id" element={<CategoryShop />} />
          <Route path="/ProductDetails/:id" element={<ProductDetails />} />
          <Route path="/Cart" element={<Cart />} />
          <Route path="/Login" element={<Login />} />
          <Route path="/Register" element={<Register />} />
          <Route path="/Checkout" element={<Checkout />} />
          <Route path="/ForgotPassword" element={<ForgotPassword />} />
          <Route path="/VerifyPassword/:id" element={<VerifyPassword />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/Wishlist" element={<Wishlist />} />
          <Route path="/Order" element={<Order />} />
          <Route path="/Getorderdetails/:id" element={<Getorderdetails />} />
          <Route path="/Faq" element={<Faq />} />
          <Route path="/AddressForm" element={<AddressForm />} />
          <Route path="/UpdateAddress/:id" element={<UpdateAddress />} />
          <Route path="/GetAddress" element={<GetAddress />} />
          <Route path="/TermsAndCondition" element={<TermsAndCondition />} />
          <Route path="/ReturnPolicy" element={<ReturnPolicy />} />
          <Route path="/Privacypolicy" element={<Privacypolicy />} />
          <Route path="/Payment" element={<Payment />} />
          <Route path="/ListReviews" element={<ListReviews />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
