import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { BiSearchAlt2 } from "react-icons/bi";
import { useSelector, useDispatch } from "react-redux";
import {
  getproducts,
  addwishlists,
  searchs,
  FilterProducts,
} from "../Actions/Action";
import { authenticatelogin } from "../Actions/Action";
import { FaHeart, FaRegHeart } from "react-icons/fa";
import products from "../noproductsfound.svg";
import Box from "@material-ui/core/Box";
import Slider from "@material-ui/core/Slider";
import { styled } from "@material-ui/core/styles";
function valuetext(value) {
  return `${value}°C`;
}
const green500 = "#000";
const green900 = "#000";
const CustomSlider = styled(Slider)(({ theme }) => ({
  color: green500, //color of the slider between thumbs
  "& .MuiSlider-thumb": {
    backgroundColor: green500, //color of thumbs
  },
  "& .MuiSlider-rail": {
    color: green900, ////color of the slider outside  teh area between thumbs
  },
}));
const minDistance = 1;
function Shop() {
  const dispatch = useDispatch();
  const [user, setUser] = useState({
    email: "",
    password: "",
  });
  const [loader, setLoader] = useState(true);
  const [productData, setProductData] = useState();
  const [passworderror, setPassworderror] = useState("");
  const [message, setMessage] = useState("");
  const product = useSelector(({ getproducts }) => getproducts.payload);
  const [load, setLoad] = useState(false);
  const [search, setSearch] = useState("");
  const Token = localStorage.getItem("AuthTok");
  const Navigate = useNavigate();
  const [value2, setValue2] = React.useState([0, 5000]);
  const [value3, setValue3] = React.useState([0, 5]);
  const handleFilter = (e) => {
    dispatch(
      FilterProducts({
        priceRange:
          window.$("#amount").val() === ""
            ? ["200", "15000"]
            : window.$("#amount").val().split("-"),
        filter: true,
      })
    )
      .then((res) => {
        if (res.data.data === "No Products Found..!") {
          setMessage("No Product Found On This Filter !");
          dispatch(getproducts());
        } else {
          setProductData(res?.data?.data);
        }
      })
      .catch((err) => {});
  };
  const handleChange2 = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }
    if (newValue[1] - newValue[0] < minDistance) {
      if (activeThumb === 0) {
        const clamped = Math.min(newValue[0], 5000 - minDistance);
        setValue2([clamped, clamped + minDistance]);
      } else {
        const clamped = Math.max(newValue[1], minDistance);
        setValue2([clamped - minDistance, clamped]);
      }
    } else {
      setValue2(newValue);
    }

    const string = newValue.toString();
    const ans_array = string.split(",");
    console.log(ans_array);
  };
  const handleChange3 = (event, newValues, activeThumbs) => {
    if (!Array.isArray(newValues)) {
      return;
    }
    if (newValues[1] - newValues[0] < minDistance) {
      if (activeThumbs === 0) {
        const clamped = Math.min(newValues[0], 5 - minDistance);
        setValue3([clamped, clamped + minDistance]);
      } else {
        const clamped = Math.max(newValues[1], minDistance);
        setValue3([clamped - minDistance, clamped]);
      }
    } else {
      setValue3(newValues);
    }

    const string = newValues.toString();
    const ans_array = string.split(",");
    console.log(ans_array);
  };

  useEffect(() => {
    setLoad(true);
    dispatch(getproducts()).then((res) => setLoader(false));
  }, []);
  useEffect(() => {
    window.scrollTo(1000, 0);
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (user.password.length > 3) {
      dispatch(authenticatelogin(user)).then((res) => {
        if (res.data.data === "Wrong Email/Password. Try Again!") {
          setPassworderror(res.data.data);
        } else {
          window.location.href = "/Shop";
        }
      });
    } else {
      setPassworderror("password must be  greater than 3 letters");
    }
  };
  const handleAnimationSpeed = (e) => {
    console.log(e.target.value);
  };
  const handlechange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };
  return loader ? (
    <div
      style={{
        height: "400px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  ) : (
    <>
      <div>
        <div class="page-title-area">
          <div class="container">
            <div
              class="page-title-content"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <h2>Shop </h2>
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>

                <li>Shop</li>
              </ul>
            </div>
          </div>
        </div>
        <section class="shop-area pt-50 pb-100">
          <div class="container">
            <div class="woocommerce-topbar">
              <div class="row align-items-center">
                <div class="col-lg-8 col-md-7 col-sm-6"></div>
              </div>
            </div>
            <div className="row">
              {/* <div className="col-lg-2 col-md-2 col-sm-12">
                <Box>
                  <p>Filter By Price</p>
                  <CustomSlider
                    min={0}
                    max={5000}
                    value={value2}
                    onChange={handleChange2}
                    valueLabelDisplay="auto"
                    color="dark"
                    disableSwap
                  />
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <p
                      style={{
                        marginTop: "15px",
                        color: "black",
                        fontFamily: "Inter",
                      }}
                    >
                      Price ₹
                    </p>
                    <button
                      style={{
                        border: "none",
                        background: "none",
                        color: "black",
                        fontFamily: "Inter",
                      }}
                      type="button"
                      onClick={handleFilter}
                    >
                      Apply
                    </button>
                  </div>
                </Box>
                <Box>
                  <p>Filter By Rating</p>
                  <CustomSlider
                    min={0}
                    max={5}
                    value={value3}
                    onChange={handleChange3}
                    valueLabelDisplay="auto"
                    color="dark"
                    disableSwap
                  />
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <p
                      style={{
                        marginTop: "15px",
                        color: "black",
                        fontFamily: "Inter",
                      }}
                    >
                      Rating (☆)
                    </p>
                    <button
                      style={{
                        border: "none",
                        background: "none",
                        color: "black",
                        fontFamily: "Inter",
                      }}
                      type="button"
                      onClick={handleFilter}
                    >
                      Apply
                    </button>
                  </div>
                </Box>
              </div> */}
              <div className="col-12 ">
                <div class="row ">
                  {Array.isArray(product) && product.length != 0 ? (
                    product
                      ?.filter((Y) => Y.discountPrice >= 1)
                      .map((x) => (
                        <div class="col-xl-3 col-lg-4 col-6">
                          <div class="products-item">
                            <div class="image">
                              <Link to={"/ProductDetails/" + x.id}>
                                <img
                                  src={
                                    process.env.REACT_APP_BASE_URL +
                                    x.productImage
                                  }
                                  alt="hjuiiu"
                                  style={{
                                    width: "100%",
                                    aspectRatio: "1 / 1",
                                  }}
                                />
                              </Link>
                              <ul class="social" style={{ marginTop: "-60px" }}>
                                {Token ? (
                                  <li>
                                    <a
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        fontSize: "20px",
                                      }}
                                      onClick={() => {
                                        setLoader(true);
                                        dispatch(
                                          addwishlists({ productId: x.id })
                                        ).then((res) => {
                                          dispatch(getproducts()).then((res) =>
                                            setLoader(false)
                                          );
                                        });
                                      }}
                                    >
                                      {loader ? (
                                        <></>
                                      ) : x.favourites === 0 ? (
                                        <span>
                                          <FaRegHeart
                                            style={{ color: "black" }}
                                          />
                                        </span>
                                      ) : (
                                        <span>
                                          <FaHeart style={{ color: "black" }} />
                                        </span>
                                      )}
                                    </a>
                                  </li>
                                ) : (
                                  <li>
                                    <a
                                      data-bs-toggle="modal"
                                      data-bs-target="#exampleModal"
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        fontSize: "20px",
                                      }}
                                    >
                                      {loader ? (
                                        <></>
                                      ) : x.favourites === 0 ? (
                                        <span>
                                          <FaRegHeart
                                            style={{ color: "black" }}
                                          />
                                        </span>
                                      ) : (
                                        <span>
                                          <FaHeart style={{ color: "black" }} />
                                        </span>
                                      )}
                                    </a>
                                  </li>
                                )}
                              </ul>
                              <div
                                class="modal fade"
                                id="exampleModal"
                                tabindex="-1"
                                aria-labelledby="exampleModalLabel"
                                aria-hidden="true"
                              >
                                <div class="modal-dialog modal-dialog-centered">
                                  <div class="modal-content">
                                    <div class="modal-header">
                                      <h5
                                        class="modal-title"
                                        id="exampleModalLabel"
                                      >
                                        Login
                                      </h5>
                                      <button
                                        type="button"
                                        class="btn-close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                      ></button>
                                    </div>
                                    <div class="modal-body">
                                      <form onSubmit={handleSubmit}>
                                        <div class="row">
                                          <div class="col-lg-12 mt-3 mb-3">
                                            <div class="form-group">
                                              <input
                                                type="email"
                                                class="form-control"
                                                placeholder="Email"
                                                name="email"
                                                style={{ borderRadius: "0px" }}
                                                onChange={handlechange}
                                                value={user.email}
                                                required
                                              />
                                            </div>
                                          </div>
                                          <div class="col-lg-12 mt-3">
                                            <div class="form-group">
                                              <input
                                                type="password"
                                                class="form-control"
                                                placeholder="Password"
                                                name="password"
                                                style={{ borderRadius: "0px" }}
                                                onChange={handlechange}
                                                value={user.password}
                                                required
                                              />
                                              <span
                                                style={{
                                                  color: "brown",
                                                  marginLeft: "20px",
                                                }}
                                              >
                                                {passworderror}
                                              </span>
                                            </div>
                                          </div>
                                          <div class="col-lg-12 mb-3">
                                            <p class="forgot-password">
                                              <a href="/Register">
                                                Don't you have an account?{" "}
                                                <b>Register</b>
                                              </a>
                                            </p>
                                          </div>
                                          <div class="col-lg-12">
                                            <div class="send-btn">
                                              <button
                                                type="submit"
                                                class="default-btn"
                                              >
                                                Login
                                                <span></span>
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </form>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div class="shop-btn">
                                <Link
                                  to={"/ProductDetails/" + x.id}
                                  style={{ cursor: "pointer" }}
                                  class="default-btn"
                                >
                                  View Product
                                </Link>
                              </div>
                            </div>
                            <div class="content p-4">
                              <h3>
                                <Link to={"/ProductDetails/" + x.id}>
                                  {x.productName}
                                </Link>
                              </h3>
                              <span>₹{x.discountPrice}</span>
                            </div>
                          </div>
                        </div>
                      ))
                  ) : (
                    <center className="pb-5 mb-5">
                      <img
                        src={products}
                        className="mt-5 mb-5"
                        style={{ width: "25%" }}
                      />
                      <h3 className="container">No Product Found</h3>
                    </center>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default Shop;
