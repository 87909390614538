import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getcarts, removecarts, updatecarts } from "../Actions/Action";
import cartimage from "../shopping_cart.svg";
function Cart() {
  const [load, setLoad] = useState(true);
  const [quantity, setQuantity] = useState("");
  const getCart = useSelector(({ getcarts }) => getcarts.payload);
  const [render, setRender] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    window.scrollTo(1000, 0);
  }, []);
  useEffect(() => {
    dispatch(getcarts())
      .then((res) => setLoad(false))
      .catch((res) => setLoad(false));
  }, [render]);

  useEffect(() => {
    if (getCart?.cart) {
      setQuantity(getCart?.cart?.map((A) => A.units));
    }
  }, [getCart]);
  const handleClickplus = (i, C) => {
    dispatch(updatecarts({ units: "add", cartId: C.id }));
    quantity[i] = quantity[i] + 1;
    setRender(!render);
  };
  const handleClickminus = (i, C) => {
    dispatch(updatecarts({ units: "sub", cartId: C.id }));
    quantity[i] = quantity[i] - 1;
    setRender(!render);
  };

  const styles = {
    textAlign: "center",
  };
  return load ? (
    <div
      style={{
        height: "400px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  ) : (
    <div>
      <div class="page-title-area">
        <div class="container">
          <div
            class="page-title-content"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <h2>Cart</h2>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>Cart</li>
            </ul>
          </div>
        </div>
      </div>
      <section class="cart-area ptb-100">
        <div class="container">
          <div class="row">
            <div class="col-lg-12 col-md-12">
              <form>
                <div class="cart-table table-responsive">
                  {Array.isArray(getCart?.cart) &&
                  getCart?.cart?.length != 0 ? (
                    <table class="table table-bordered">
                      <thead>
                        <tr>
                          <th scope="col" style={styles}>
                            S.No
                          </th>
                          <th scope="col" style={styles}>
                            Product
                          </th>
                          <th scope="col" style={styles}>
                            Name
                          </th>
                          <th scope="col" style={styles}>
                            Unit Price
                          </th>
                          <th scope="col" style={styles}>
                            Quantity
                          </th>
                          <th scope="col" style={styles}>
                            Total
                          </th>
                          <th scope="col" style={styles}>
                            Remove
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {Array.isArray(getCart?.cart) &&
                        getCart?.cart?.length != 0 ? (
                          getCart?.cart.map((C, i) => (
                            <tr>
                              <td style={styles} class="product-name">
                                <a style={{ color: "black" }}>{i + 1}</a>
                              </td>
                              <td style={styles} class="product-thumbnail">
                                <Link to={"/ProductDetails/" + C.productId}>
                                  <img
                                    src={
                                      process.env.REACT_APP_BASE_URL +
                                      C.variantImage
                                    }
                                    alt="item"
                                  />
                                </Link>
                              </td>
                              <td style={styles} class="product-name">
                                <Link
                                  to={"/ProductDetails/" + C.productId}
                                  style={{
                                    textTransform: "capitalize",
                                    color: "black",
                                  }}
                                >
                                  {C.productName}
                                </Link>
                              </td>
                              <td style={styles} class="product-price">
                                <span class="unit-amount">
                                  ₹ {C.singleProductPrice}
                                </span>
                              </td>
                              <td style={styles} class="product-quantity">
                                <div class="input-counter">
                                  <span
                                    class="minus-btn"
                                    onClick={() => {
                                      if (parseInt(C.units) > 1)
                                        handleClickminus(i, C);
                                    }}
                                  >
                                    <i class="bx bx-minus"></i>
                                  </span>
                                  <input
                                    type="text"
                                    max="1"
                                    value={C.units}
                                    onChange={(e) =>
                                      setQuantity(e.target.value)
                                    }
                                  />
                                  <span
                                    class="plus-btn"
                                    onClick={() => handleClickplus(i, C)}
                                  >
                                    <i class="bx bx-plus"></i>
                                  </span>
                                </div>
                              </td>
                              <td style={styles} class="product-subtotal">
                                <span class="subtotal-amount">
                                  ₹{C.totalPrice}
                                </span>
                              </td>
                              <td style={styles}>
                                <span
                                  onClick={() => {
                                    setLoad(true);
                                    dispatch(
                                      removecarts({ cartId: C.id })
                                    ).then((res) => setLoad(false));
                                  }}
                                  class="remove"
                                  style={{ cursor: "pointer" }}
                                >
                                  <i
                                    style={{ fontSize: "23px" }}
                                    class="bx bx-trash"
                                  ></i>
                                </span>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <center>
                            <h3 className="mt-5 mb-5 pb-5 pt-5">
                              "No Product Found!"
                            </h3>{" "}
                          </center>
                        )}
                      </tbody>
                    </table>
                  ) : (
                    <center>
                      <img
                        src={cartimage}
                        className="mt-5 mb-5  pt-5"
                        style={{ width: "25%" }}
                      />
                      <h3 className="mb-5 pb-3"> No Products Found</h3>{" "}
                    </center>
                  )}
                </div>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  {Array.isArray(getCart?.cart) &&
                    getCart?.cart?.length != 0 && (
                      <Link to="/checkout" class="default-btn">
                        Checkout
                      </Link>
                    )}
                </div>

                {/* {Array.isArray(getCart?.cart) && getCart?.cart?.length != 0 ? (
                  <div div style={{ display: "flex" }}>
                    <div
                      className="mt-5 col-6"
                      style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                      }}
                    ></div>
                    <div
                      className="mt-5 col-6"
                      style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                      }}
                    >
                      <div>
                        <div style={{ fontSize: "18px" }}>Sub Total</div>
                        <div style={{ fontSize: "18px" }}> GST</div>
                        <div style={{ fontSize: "18px" }}> Discount</div>
                        <div style={{ fontSize: "18px" }}>Price</div>
                        <div style={{ fontSize: "18px" }}> Total Price</div>
                      </div>
                      <div>
                        <div style={{ fontSize: "18px" }}>
                          ₹ {getCart?.subtotal}
                        </div>
                        <div style={{ fontSize: "18px" }}>₹ {getCart?.gst}</div>
                        <div style={{ fontSize: "18px" }}>
                          ₹ {getCart?.discount}
                        </div>
                        <div style={{ fontSize: "18px" }}>
                          ₹ {getCart?.price}
                        </div>
                        <div style={{ fontSize: "18px" }}>
                          ₹ {getCart?.totalPrice}
                        </div>
                      </div>{" "}
                    </div>
                  </div>
                ) : (
                  false
                )} */}
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Cart;
